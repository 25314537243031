import IndexView from './pages/IndexView';
import PessoaView from './pages/PessoaView';

export default [
    {
    path: '/dashboard/creddefense',
    name: 'dashboard',
    component: IndexView,
    meta:{permission: 'dashboard.creddefense'}
    },
    {
    path: '/dashboard/creddefense/:id',
    name: 'creddefenseId',
    component: PessoaView,
    meta:{permission: 'dashboard.creddefense.pessoa'}
    }, 
]