import IndexView from './pages/IndexView';

export default [
    {
        path: '/fraudes/parametros/:id',
        name: 'parametros.fraudes',
        component: IndexView,
        meta: {
            permission: 'parametros.fraudes'
        }
    }, 
    {
        path: '/recuperacoes/parametros/:id',
        name: 'parametros.recuperacoes',
        component: IndexView,
        meta: {
            permission: 'parametros.recuperacoes'
        }
    }
];