const flash = {
    methods: {
        flash(type, message, duration = 5000) {            
            this.$buefy.toast.open({
                message: message, 
                type: 'is-' + type, 
                duration: 5000
            })
        }
    }
}

export default flash;