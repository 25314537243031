import Vue from 'vue';
import VueCookies from 'vue-cookies';
import App from './App.vue';
import router from './router';
import store from './store';
import VueTheMask from 'vue-the-mask';
import acl from './acl';
import '@fortawesome/fontawesome-free/css/all.css';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import Multiselect from 'vue-multiselect';
import VueFilterDateFormat from '@vuejs-community/vue-filter-date-format';

// Componentes Movida
import Layout from '@/components/Layout.vue';
import PageTitle from '@/components/PageTitle.vue';
import Attribute from '@/components/Attribute.vue';
import Card from '@/components/Card.vue';

import Buefy from 'buefy'

import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DNS,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["my-site-url.com", /^\//],
    }),
  ],
  tracesSampleRate: 1.0,
});

Vue.config.productionTip = false

Vue.use(Buefy, { defaultIconPack: 'fas' });
Vue.use(VueTheMask);
Vue.use(VueFilterDateFormat);
Vue.use(VueCookies, { expires: '1d'})

Vue.component('layout', Layout);
Vue.component('multiselect', Multiselect);
Vue.component('page-title', PageTitle);
Vue.component('attribute', Attribute);
Vue.component('card', Card);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
