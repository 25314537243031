<template>
    <div class="navbar-component">
        <b-navbar>

            <template #brand>
                <b-navbar-item tag="router-link" :to="{ path: '/' }">
                    <div class="p-3 is-flex is-justify-content-center ">
                        <img alt="movida logo" src="../assets/logo.svg">
                    </div>
                </b-navbar-item>
            </template>

            <template #start>
                
            </template>

            <template #end>
                <b-navbar-item tag="div">
                    <div class="">
                        <a class="icon-usuario column is-flex" href="#">
                            <b-icon
                                pack="fas"
                                icon="user"
                                size="is-small">
                            </b-icon>
                            <p class="p-1"> {{ user }}</p>
                        </a>
                    </div>
                    <div class="mobile">
                        <MenuItens />
                    </div>
                </b-navbar-item>
            </template>
        </b-navbar>
    </div>
</template>

<style scoped>
    .navbar-component{
        padding-bottom: 60px;
    }

    .navbar{
        padding: 0;
        border-bottom: #ccc 1px solid;
        position: fixed;
        height: 60px;
        width: 100%;
        z-index: 5;
    }

    a.icon-usuario{
        border-left: solid 1px #D4D4D4;
    }

    a.icon-usuario:hover{
        background-color: #FFF8F1;
    }

    
    a.icon-usuario:hover p{
        color: #F56C29;
    }

    a.icon-usuario span{
        background-color: #EAEAEA;
        padding: 15px 15px;
        border-radius: 50%;
        color: #fff;
        margin: 0 15px;
    }

    .mobile{
        display: none;
    }

    @media screen and (max-width: 768px){
        .navbar-component{
        padding-bottom: 0;
        }

        .navbar{
            position: relative;
        }

        .mobile{
            display: block;
        }
    }
</style>

<script>

import { mapState } from 'vuex'

import MenuItens from '@/components/MenuItens.vue';

export default {
    components: {
        MenuItens
    },
    data() {
        return {
            nome: ''
        }
    },
    computed: {
        
        ...mapState({
        user(state) {
            return state.auth.user?.nome;
        } 
    })}

    
}
</script>
