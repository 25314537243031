<template>
    <div class="is-attribute">
        <small class="is-block has-text-grey">{{ title }}</small>
        <span class="is-block">
            <slot></slot>
        </span>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String, 
            required: true 
        }, 
        text: {
            type: String
        }
    }
}
</script>

<style>
    .is-attribute {
        padding: 10px 20px;
        background: hsl(0, 0%, 96%);
        border-radius: 6px;
    }


</style>