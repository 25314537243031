<template>
	<layout>
		
		<div v-if="(recurso != null)">
			<page-title subtitle="Exibindo detalhes da origem para solicitação de análise">
				{{ recurso.Descricao }} - Origens - Solicitações de análise
			</page-title>
			<hr />

			<card title="Informações da origem">
				<div class="columns">
					<div class="column">
						<attribute title="Descrição">
							{{ recurso.Descricao }}
						</attribute>
					</div>
				</div>
				<div class="columns">
					<div class="column is-4">
						<attribute title="Status">
							<span class="tag" :class="[recurso.FlgAtivo == 'S' ? 'is-success' : 'is-danger']" >{{ recurso.FlgAtivo == "S" ? "Ativo" : "Inativo" }}</span>
						</attribute>
					</div>
					<div class="column">
						<attribute title="ID da origem">
							{{ recurso.AnaliseOrigemID }}
						</attribute>
					</div>
				</div>
				<div class="columns">
					<div class="column">
						<attribute title="Data de criação">
							<span v-if="(recurso.created_at != null)">{{ new Date(recurso.created_at) | dateFormat('DD/MM/YYYY') }}</span>
							<span v-else>-</span>
						</attribute>
					</div>
					<div class="column">
						<attribute title="Data de atualização">
							<span v-if="(recurso.updated_at != null)">{{ new Date(recurso.updated_at) | dateFormat('DD/MM/YYYY') }}</span>
							<span v-else>-</span>
						</attribute>
					</div>
				</div>
			</card>

			<!-- Associações com a análise -->
			<analise-origem-perfil></analise-origem-perfil>
			<analise-origem-motivo></analise-origem-motivo>
			<analise-origem-tipo></analise-origem-tipo>

			<router-link :to="{ name: 'analise.origem' }">
            	<b-button type="is-primary" outlined><i class="fa-solid fa-circle-xmark fa-fw"></i> Fechar</b-button>
          	</router-link>

		</div>

		<b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
	</layout>
</template>

<script>

import flash from '@/mixins/flash';


export default {
	
	mixins: [flash],

	components: {
    AnaliseOrigemPerfil: () => import('@/modules/analise/origem/components/AnaliseOrigemPerfil.vue'), 
	AnaliseOrigemMotivo: () => import('@/modules/analise/origem/components/AnaliseOrigemMotivo.vue'),
	AnaliseOrigemTipo: () => import('@/modules/analise/origem/components/AnaliseOrigemTipo.vue'),
  },
	
	data() {
		return {
			isLoading: true, 
			recurso: null
		}
	},
	
	methods: {
		fetch() {
			this.isLoading = true

			this.$http.get('api/v1/analise/origem/' + this.$route.params.id).then((response) => {
				if (response.body.success) {
					this.recurso = response.body.response
					this.$root.$emit('resource-loaded')
				} else {
					this.flash('danger', 'Falha ao carregar a origem selecionada')
				}

				this.isLoading = false
			}).catch((error) => {
				console.log(error)
				this.flash('danger', 'Falha ao carregar a origem selecionada')
			})
		}
	},

	mounted() {
		this.fetch()
	}
	
};
</script>
