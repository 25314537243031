import services from '@/http';
import * as types from './mutation-types';
import * as storage from '../storage';


export const ActionCheckToken = ({ dispatch, state }) => {
    if (state.token) {
      return Promise.resolve(state.token)
    }

    let token = storage.getLocalToken()

    if(token == "" || token ==null){
        token = $cookies.get("token_user")
    }

    if (!token) {
      return Promise.reject(new Error('Token Inválido'))
    }

    dispatch('ActionSetToken', token)
    return dispatch('ActionLoadSession')
}

export const ActionLoadSession = ({ dispatch }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const user = await services.auth.loadSession()
        dispatch('ActionSetUser', user.data.data)
        if (user.data.data.acessos) {
          dispatch('ActionSetPermissions', user.data.data.acessos)
        }
        

        resolve()
      } catch (err) {
        dispatch('ActionSignOut')
        console.error(err)
      }
    })
}

export const ActionSetUser = ({ commit }, payload) => {
    commit(types.SET_USER, payload)
}

export const ActionSetPermissions = ({ commit }, payload) => {
    storage.setPermissions(JSON.stringify(payload))
}

export const ActionSetToken = ({ commit }, payload) => {
    storage.setHeaderToken(payload)
    storage.setLocalToken(payload)
    commit(types.SET_TOKEN, payload)
}

export const ActionSignOut = ({ dispatch }) => {
    storage.setHeaderToken('')
    storage.deleteLocalToken()
    dispatch('ActionSetUser', {})
    dispatch('ActionSetToken', '')
    storage.deleteCookieToken()
    storage.deleteCookieDomainToken()
  }