<template>
    <div>
        <b-loading v-model="isLoading"></b-loading>
        <div class="box" v-if=" ! isLoading">

            <h4 class="title is-6">Vincular status ao tipo de operação</h4>

            <div v-for="(unidade, index) in unidadesNegocio" v-bind:key="index" class="mb-4">

                <b-dropdown
                    v-model="statusSelecionados[unidade.L000_UnidadeNegocioID]"
                    :scrollable="true"
                    :max-height="200"
                    multiple
                    >
                    <template #trigger>
                        <b-button expanded>
                            <i class="fa-solid fa-circle-chevron-down fa-fw mr-1"></i> <strong>{{ unidade.UnidadeNegocio }}</strong> {{ statusSelecionados[unidade.L000_UnidadeNegocioID].length }} escolhido(s)
                        </b-button>
                    </template>

                    <b-dropdown-item v-for="(statusPorUnidade, index) in statusDisponiveisPorUnidadeNegocio[unidade.L000_UnidadeNegocioID]" :value="statusPorUnidade.StatusID" v-bind:key="index">
                        {{ statusPorUnidade.Status }}
                    </b-dropdown-item>

                </b-dropdown>
            </div>

            <hr>
            <b-button type="is-primary pulled-right" @click="sync"><i class="fa-solid fa-floppy-disk fa-fw mr-1"></i> Salvar</b-button>
        </div>
    </div>
    
</template>

<script>

import services from '@/http';

export default {

    props: ['tipo'], 

    data() {
        return {
            isLoading: true, 
            operacaoStatus: [], 
            statusSelecionados: [], 
            unidadesNegocio: [], 
            statusDisponiveisPorUnidadeNegocio: [], 
        }
    }, 

    methods: {
        
        fetchUnidadeNegocio() {
            
            services.unidadeNegocio.get().then(res => {

                console.log('Unidade negocio fetched!')

                if (res.body.success) {
                    this.unidadesNegocio = res.body.response

                    let status = [];

                    res.body.response.map((item) => {
                        this.fetchStatus(item.L000_UnidadeNegocioID)
                        status[item.L000_UnidadeNegocioID] = []
                    });
                    
                    this.statusSelecionados = status;

                    setTimeout(this.fetchOperacaoStatus, 3000);
                }
            })
        }, 
        fetchStatus(unidadeNegocio) {
            this.$http.get('api/v1/status/frota?removerStatus=1&operacao=' + this.$route.params.id + '&unidadeNegocio=' + unidadeNegocio).then(res => {
                if (res.body.success) {
                     console.log('Status fetched!')
                    this.statusDisponiveisPorUnidadeNegocio[unidadeNegocio] = res.body.response
                }
            })
        }, 
        fetchOperacaoStatus() {

            this.$http.get('api/v1/status/frota/operacao/' + this.tipo.TipoOperacaoID).then(res => {
                if (res.body.success) {
                    
                     console.log('Operação status fetched!')
                    let selecionados = this.statusSelecionados;
                    
                    res.body.response.map(function(item) {
                        selecionados[item.unidade_negocio.L000_UnidadeNegocioID].push(item.StatusID)
                    })

                    this.statusSelecionados = selecionados;

                    this.isLoading = false;
                }
                
            })
        }, 
        sync() {
            this.$emit('sync', { status: this.statusSelecionados })
        }
    }, 

    created() {
        this.fetchUnidadeNegocio()
    }
}

</script>