import AnaliseFraudeView from './pages/AnaliseFraudeView';
import AnaliseFraudeIdView from './pages/AnaliseFraudeIdView';

export default [
    {
    path: '/dashboard/analiseFraude',
    name: 'analiseFraude',
    component: AnaliseFraudeView,
    meta:{permission: 'dashboard.analiseFraude'}
    },
    {
    path: '/dashboard/analiseFraude/:id',
    name: 'analiseFraudeId',
    component: AnaliseFraudeIdView,
    meta:{permission: 'dashboard.analiseFraude'}
    }, 
]