<template>
    <layout>

        <b-loading :is-full-page="true" v-model="isLoading"></b-loading>

        <page-title subtitle="Listando as configurações e parâmetros de filial disponíveis para o módulo">
            Parâmetros de Filial
        </page-title>

        <!-- Registros-->
        <card title="Parâmetros">

            <div>
                <div class="columns">
                <div class="column is-4 is-half-tablet">
                    <b-field
                        class=""
                        label="Selecione um parametro"
                        >
                        <b-select
                            @change.native="parametro_detalhes"
                            v-model="parametro"
                            placeholder="Selecione um parametro para editar"
                            expanded
                        >
                            <option
                            v-for="parametro in parametros"
                            v-bind:key="parametro.ParametroID"
                            :value="parametro.ParametroID"
                            >
                            {{ parametro.Descricao }}
                            </option>
                        </b-select>
                    </b-field>
                </div>
                <div class="column is-4 is-half-tablet">
                    <b-field
                        class=""
                        label="Conteúdo padrão do parâmetro"
                        >

                        <!-- string -->
                        <b-input v-if="parametroDados.RegraValidacao.type == 'string'" v-model="parametroDados.conteudo"></b-input>

            
                        <!-- Integer -->
                        <b-input type="number" v-if="parametroDados.RegraValidacao.type == 'integer'" v-model="parametroDados.conteudo"></b-input>
                         
                        <!-- Select -->
                        <b-select expanded v-if="parametroDados.RegraValidacao.type == 'select'" v-model="parametroDados.conteudo" placeholder="Selecione...">
                            <option v-for="(option, optionIndex) in parametroDados.RegraValidacao.data" v-bind:key="optionIndex" :value="option.value">
                                {{ option.label }}
                            </option>
                        </b-select>

                        <!-- Select com tabelas -->
                        <b-select expanded v-if="parametroDados.RegraValidacao.type == 'table'" :loading="parametroDados.Loading" v-model="parametroDados.conteudo" placeholder="Selecione...">
                            <option v-for="(option, optionIndex) in parametroDados.RegraValidacao.data.options" v-bind:key="optionIndex" :value="option.value">
                                {{ option.label }}
                            </option>
                        </b-select>

                        <!-- Date -->
                        <b-datepicker 
                            v-if="parametroDados.RegraValidacao.type == 'date'" 
                            v-model="parametroDados.conteudo" 
                            icon="calendar"
                        ></b-datepicker>

                    </b-field>
                </div>
                <div class="column is-4 is-half-tablet">
                    <b-field 
                        class=""
                        label="Forçar utilização do conteúdo padrão?"
                        >
                        <b-select v-if="parametroDados.flgByPassGlobal != null"
                            v-model="parametroDados.flgByPassGlobal"
                            placeholder=""
                            expanded
                        >
                            <option value="S">Sim</option>
                            <option value="N">Não</option>
                        </b-select>
                    </b-field>
                </div>

                </div>
                <b-button @click="updateParametro" type="is-primary pulled-right"><i class="fa-solid fa-floppy-disk fa-fw mr-1"></i> Salvar</b-button>

                <b-button v-if="btnMostrarFiliais" @click="abrir_modal_cadastro" type="ml-2 is-primary pulled-right"><i class="fa-solid fa-store fa-fw mr-1"></i> Adicionar Filiais</b-button>
            </div>
        </card>

        <div>
                <!-- <table v-if="(parametro_filial.length > 0)" class="table is-fullwidth is-hoverable">
                    <thead>
                        <tr>
                            <th>Filial</th>
                            <th>Conteúdo</th>
                            <th>Flag Ativo</th>
                            <th>-</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in parametro_filial" :key="item.ParametroFilialID">
                            <td class="has-text-weight-bold">{{ item.filial.Filial }}</td>
                            <td>{{ item.Conteudo }}</td>
                            <td class="has-text-centered">
                                <b-button @click="updateStatus(item)"
                                    class="tag"
                                    :class="[item.FlgAtivo == 'S' ? 'is-success' : 'is-danger']"
                                    >{{ item.FlgAtivo == "S" ? "Ativo" : "Inativo" }}</b-button>
                            </td>
                            <td><b-button
                                label="Editar"
                                type="is-primary"
                                size="is-small"
                                @click="abrir_modal(item)" />
                            </td>
                        </tr>
                    </tbody>
                </table> -->

                <b-table
                    :data="parametro_filial">
                    <template v-for="column in columns">
                        <b-table-column :key="column.id" v-bind="column">
                            <template
                                v-if="column.searchable"
                                #searchable="props">
                                <b-input
                                    v-model="props.filters[props.column.field]"
                                    placeholder="Search..."
                                    icon="magnify"
                                    size="is-small" />
                            </template>
                      
                            <template  v-slot="props">
                                <span v-if="column.field == 'filial.Filial'"> {{ props.row.filial.Filial  }}</span>
                                <span v-else-if="column.field == 'flgAtivo'">
                                    <b-button @click="updateStatus(props.row)"
                                    class="tag"
                                    :class="[props.row.FlgAtivo == 'S' ? 'is-success' : 'is-danger']"
                                    >{{ props.row.FlgAtivo == "S" ? "Ativo" : "Inativo" }}</b-button>
                                </span>
                                <span v-else-if="column.field == 'actions'">
                                    <b-button
                                    label="Editar"
                                    type="is-primary"
                                    size="is-small"
                                    @click="abrir_modal(props.row)" />
                                </span>
                                <span v-else> {{ props.row[column.field] }}</span>

                            </template>
                        </b-table-column>
                    </template>
                </b-table>
                <b-modal v-model="isFormModalActive">
                    <div class="box">
                        <div class="mb-4">
                            <form>
                                <div class="field">
                                    <label class="label">Conteúdo</label>
                                    <div class="control">

                                        <b-input v-if="parametroDados.RegraValidacao.type == 'string'" v-model="parametroModalFields.conteudo"></b-input>

                                        <!-- Integer -->
                                        <b-input type="number" v-if="parametroDados.RegraValidacao.type == 'integer'" v-model="parametroModalFields.conteudo"></b-input>
                                        
                                        <!-- Select -->
                                        <b-select expanded v-if="parametroDados.RegraValidacao.type == 'select'" v-model="parametroModalFields.conteudo" placeholder="Selecione...">
                                            <option v-for="(option, optionIndex) in parametroDados.RegraValidacao.data" v-bind:key="optionIndex" :value="option.value">
                                                {{ option.label }}
                                            </option>
                                        </b-select>

                                        <!-- Select com tabelas -->
                                        <b-select expanded v-if="parametroDados.RegraValidacao.type == 'table'" :loading="parametroDados.Loading" v-model="parametroModalFields.conteudo" placeholder="Selecione...">
                                            <option v-for="(option, optionIndex) in parametroDados.RegraValidacao.data.options" v-bind:key="optionIndex" :value="option.value">
                                                {{ option.label }}
                                            </option>
                                        </b-select>

                                        <!-- Date -->
                                        <b-datepicker 
                                            v-if="parametroDados.RegraValidacao.type == 'date'" 
                                            v-model="parametroModalFields.conteudo" 
                                            icon="calendar"
                                        ></b-datepicker>

                                    </div>
                                </div>
                                <div class="field">
                                    <label class="label">Status</label>
                                    <div class="control">
                                        <b-switch v-model="parametroModalFields.status" true-value="S" false-value="N"></b-switch>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <hr>
                        <div>
                            <b-button @click="update" type="is-primary pulled-right"><i class="fa-solid fa-floppy-disk fa-fw mr-1"></i> Salvar</b-button>

                            <b-button
                            class="button is-pulled-right"
                            label="Fechar"
                            @click="isFormModalActive = false" />
                        </div>
                        </div>
                </b-modal>

                <b-modal 
                    v-model="isFormFilialModalActive"
                    close-button-aria-label="Close"
                    aria-modal
                    @close="close_modal_filial_event"
                    >
                    <div class="box">
                        <div class="mb-4">
                            <form>
                                <div class="field">
                                    <label class="label">Filiais</label>
                                    <div class="control">
                                        <b-field class="pr-0 pl-0 is-align-self-flex-end" label="Status">
                                            <multiselect 
                                                    v-model="parametroCadastroModalFields.filial" 
                                                    :options="filialNaoAdicionada" 
                                                    :multiple="true" 
                                                    :select-label="''"
                                                    :deselect-label="'x'"
                                                    :close-on-select="false" 
                                                    :clear-on-select="false" 
                                                    :preserve-search="true" placeholder="Selecione" label="Filial" 
                                                    track-by="FilialID" 
                                                    :preselect-first="false">
                                                <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} {{ values.length > 1 ? 'Escolhidos' : 'Escolhido' }}</span></template>
                                            </multiselect>
                                        </b-field>
                                    </div>
                                </div>
                                <div class="field">
                                    <label class="label">Conteúdo</label>
                                    <div class="control">
                                        <!-- <input class="input" type="text" v-model="parametroCadastroModalFields.conteudo"> -->

                                        <!-- string -->
                                        <b-input v-if="parametroDados.RegraValidacao.type == 'string'" v-model="parametroCadastroModalFields.conteudo"></b-input>

                                        <!-- Integer -->
                                        <b-input type="number" v-if="parametroDados.RegraValidacao.type == 'integer'" v-model="parametroCadastroModalFields.conteudo"></b-input>
                                        
                                        <!-- Select -->
                                        <b-select expanded v-if="parametroDados.RegraValidacao.type == 'select'" v-model="parametroCadastroModalFields.conteudo" placeholder="Selecione...">
                                            <option v-for="(option, optionIndex) in parametroDados.RegraValidacao.data" v-bind:key="optionIndex" :value="option.value">
                                                {{ option.label }}
                                            </option>
                                        </b-select>

                                        <!-- Select com tabelas -->
                                        <b-select expanded v-if="parametroDados.RegraValidacao.type == 'table'" :loading="parametroDados.Loading" v-model="parametroCadastroModalFields.conteudo" placeholder="Selecione...">
                                            <option v-for="(option, optionIndex) in parametroDados.RegraValidacao.data.options" v-bind:key="optionIndex" :value="option.value">
                                                {{ option.label }}
                                            </option>
                                        </b-select>

                                        <!-- Date -->
                                        <b-datepicker 
                                            v-if="parametroDados.RegraValidacao.type == 'date'" 
                                            v-model="parametroCadastroModalFields.conteudo" 
                                            icon="calendar"
                                        ></b-datepicker>
                                        
                                    </div>
                                </div>
                                <div class="field">
                                    <label class="label">Status</label>
                                    <div class="control">
                                        <b-switch v-model="parametroCadastroModalFields.status" true-value="S" false-value="N"></b-switch>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <hr>
                        <div>
                        <b-button @click="create_parametro_filial" type="is-primary pulled-right"><i class="fa-solid fa-floppy-disk fa-fw mr-1"></i> Salvar</b-button>

                        <b-button
                            class="button is-pulled-right"
                            label="Fechar"
                            @click="close_modal_filial()" />
                        </div>

                        </div>
                </b-modal>
        </div>
<!-- 
        <div class="card">
            <div class="card-content">
                <b-button @click="update" class="is-primary is-large"><i class="fa-regular fa-floppy-disk fa-fw mr-1"></i> Salvar configurações</b-button>
            </div>
        </div> -->

        

    </layout>
</template>


<script>

    import flash from '@/mixins/flash.js';
    import { mapState } from 'vuex'


    export default {

        mixins: [flash], 

        data() {
            return {
                isLoading: true, 
                parametros: {
                    RegraValidacao: {
                        type: null, 
                        data: null
                    },
                    Conteudo: null,
                    ParametroID: null
                },
                filialNaoAdicionada: [],
                parametro: null,
                parametro_filial: [],
                isFormModalActive: false,
                isFormFilialModalActive: false,
                btnMostrarFiliais: false,
                parametroModalFields: {
                    id: null, status: null, conteudo: null
                },
                parametroCadastroModalFields: {
                    parametroID: null, filial: null, status: 'S', conteudo: null
                },
                parametroDados: {
                    id: null, conteudo: null, flgByPassGlobal: null, conteudoTableValue: null, Loading: false,
                        RegraValidacao: {
                        type: null, 
                        data: null
                    },
                },
                columns: [
                    { 
                        field: 'filial.Filial',
                        label: 'Filial',
                        centered: false,
                        sortable: false,
                        searchable: true,
                    },
                    { 
                        field: 'Conteudo',
                        label: 'Conteúdo',
                        centered: false,
                        sortable: false,
                        searchable: false,
                        searchable: true,
                    },
                    { 
                        field: 'flgAtivo',
                        label: 'Status',
                        centered: true,
                        sortable: false,
                        searchable: false,
                    },
                    { 
                        field: 'actions',
                        label: 'Ações',
                        centered: false,
                        sortable: false,
                        searchable: false,
                    },
                ]
            }
        }, 

        methods: {
            fetch() {
                this.isLoading = true
                this.$http.get('api/v1/tela/parametro_filial?orderBy=Descricao,asc&modulo=' + this.$route.params.id).then((res) => {
                    

                    this.parametros = res.body.response

                    this.isLoading = false
                })
            }, 

            close_modal_filial(){
                this.close_modal_filial_event()
                this.isFormFilialModalActive = false
            },

            close_modal_filial_event() {
                this.parametroCadastroModalFields = {
                    parametroID: null, filial: null, status: 'S', conteudo: null
                }
            },

            abrir_modal(parametro) {
                this.parametroModalFields.id = parametro.ParametroFilialID
                this.parametroModalFields.status = parametro.FlgAtivo

                let conteudo = parametro.Conteudo


                if (this.parametroDados.RegraValidacao.type == 'date') {
                    conteudo = new Date(conteudo + 'T00:00:00')
                }

                if (this.parametroDados.RegraValidacao.type == 'table') {
                    //convert coneudo para int
                    conteudo = parametro.conteudoTableValue
                }



                this.parametroModalFields.conteudo = conteudo
                this.isFormModalActive = true
            },

            abrir_modal_cadastro() {

                let parametroSelecionado = this.parametros.find((parametro) => {
                    return parametro.ParametroID == this.parametro
                })

                this.filialNaoAdicionada = parametroSelecionado.filiaisNaoAdicionada

        
                this.parametroCadastroModalFields.parametroID = this.parametro

                console.log(this.parametro)


                this.isFormFilialModalActive = true
            },

            create_parametro_filial() {

                this.isLoading = true
                //verifica se algum dos campos está vazio

                if(this.parametroCadastroModalFields.filial == null || this.parametroCadastroModalFields.filial == ''){
                    this.flash('warning', 'Selecione uma filial')
                    this.isLoading = false
                    return false
                }

                if(this.parametroCadastroModalFields.conteudo == null || this.parametroCadastroModalFields.conteudo == ''){
                    this.flash('warning', 'Preencha o campo conteúdo')
                    this.isLoading = false
                    return false
                }

                if(this.parametroCadastroModalFields.status == null || this.parametroCadastroModalFields.status == ''){
                    this.flash('warning', 'Selecione um status')
                    this.isLoading = false
                    return false
                }

                const filiais = this.parametroCadastroModalFields.filial.map((item) => {
                    return item.FilialID
                })

                let conteudo = this.parametroCadastroModalFields.conteudo  

                if (this.parametroDados.RegraValidacao.type == 'date') {
                    conteudo = conteudo.toISOString().split('T')[0]
                }

                const params = {
                    ParametroID: this.parametroCadastroModalFields.parametroID,
                    FlgAtivo: this.parametroCadastroModalFields.status,
                    Conteudo: conteudo,
                    FiliaisID: filiais,
                    Author: this.userId
                }

    

                this.$http.post('api/v1/parametro_filial/create', params ).then((res) => {
                
                    this.parametroCadastroModalFields = {
                        parametroID: null, filial: null, status: 'S', conteudo: null
                    }
                    this.isFormFilialModalActive = false

                }).finally(() => {
                    this.isLoading = false
                    this.fetch()
                    this.parametro_detalhes()
                })
            },

            updateStatus(parametro){

                const params = {
                    ParametroFilialID: parametro.ParametroFilialID,
                    FlgAtivo: parametro.FlgAtivo == 'S' ? 'N' : 'S',
                    Author: this.userId
                }

                this.isLoading = true

                this.$http.post('api/v1/parametro_filial/update', params ).then((res) => {
                

                    this.isLoading = false
                }).finally(() => {
                    this.isFormModalActive = false
                    this.parametro_detalhes()
                })

            },

            update() {
                this.isLoading = true

                let conteudo = this.parametroModalFields.conteudo  

                if (this.parametroDados.RegraValidacao.type == 'date') {
                    conteudo = conteudo.toISOString().split('T')[0]
                }

                const params = {
                    ParametroFilialID: this.parametroModalFields.id,
                    FlgAtivo: this.parametroModalFields.status,
                    Conteudo: conteudo,
                    Author: this.userId
                }

                this.isLoading = true

                

                this.$http.post('api/v1/parametro_filial/update', params ).then((res) => {
                    
        

                }).finally(() => {
                    this.isLoading = false
                    this.isFormModalActive = false
                    this.parametro_detalhes()
                })
            },

            updateParametro() {
                this.isLoading = true

                let conteudo = this.parametroDados.conteudo

                // usar date no formato yyyy-mm-dd
                if (this.parametroDados.RegraValidacao.type == 'date') {
                    conteudo = conteudo.toISOString().split('T')[0]
                }

                const params = {
                    ParametroID: this.parametroDados.id,
                    FlgByPassGlobal: this.parametroDados.flgByPassGlobal,
                    Conteudo: conteudo,

                }

                this.isLoading = true

                this.$http.post('api/v1/parametro/update', params ).then((res) => {
                    
            
                }).finally(() => {
                    this.isLoading = false

                    this.parametro_detalhes()
                })
            },

            parametro_detalhes() {
                this.btnMostrarFiliais = false

                this.isLoading = true
                this.$http.get('api/v1/parametro_filial/' + this.parametro).then((res) => {

                    this.parametro_filial = res.body.response.data.parametro_filial
                    this.parametroDados.id = res.body.response.data.ParametroID
                    let conteudo = res.body.response.data.Conteudo
                    this.parametro_filial.conteudoTableValue = conteudo
                    this.parametroDados.flgByPassGlobal = res.body.response.data.FlgByPassGlobal

                    let regraValidacao = JSON.parse(res.body.response.data.RegraValidacao)


                    if (regraValidacao.type == 'date') {
                        conteudo = new Date(conteudo + 'T00:00:00')
                    }

                    if (regraValidacao.type == 'table') {
                            this.parametroDados.Loading = true
                            this.$http.get(regraValidacao.data.endpoint).then((res) => {

                            regraValidacao.data.options = res.body.response.map((responseItem) => {
                                responseItem.label = responseItem[regraValidacao.data.label]
                                responseItem.value = responseItem[regraValidacao.data.value]
                                return responseItem
                            })
                            
                            this.parametro_filial = this.parametro_filial.map((item) => {
                                item.conteudoTableValue = item.Conteudo
                                item.Conteudo = res.body.response.find((responseItem) => {
                                    return responseItem[regraValidacao.data.value] == item.Conteudo
                                })[regraValidacao.data.label]
                                return item
                            })

                        }).finally(() => {
                            this.parametroDados.Loading = false
                        })
                    }


                    this.parametroDados.RegraValidacao = regraValidacao

                    this.parametroDados.conteudo = conteudo
                    this.btnMostrarFiliais = true
    
                }).finally(() => {

                    this.isLoading = false
                })
            }
        },
        fetchFilial() {
            this.$http.get('api/v1/filial?fetch=get&orderBy=Loja,asc&usuario=' + this.user.id).then(({ data }) => {
                if (data != null && data.response != null) {
                    this.filtros.filiais = data.response;
                    
                }
            })
        }, 

        mounted() {
            this.fetch()
        }, 
        computed: {
        ...mapState({
        userId(state) {
            return state.auth.user?.id;
        } 
        }),
        },
        
        watch: {
            '$route.params.id': {
                handler () {
                    this.fetch()
                }
            }
        },
    }

</script>
