<template>
    <div class="box">
        <h4 class="title is-6">Nova operação</h4>
        <b-field label="Descrição">
            <b-input v-model="tipo.Descricao" placeholder="Informe uma descrição..."></b-input>
        </b-field>
        <b-field label="Ativo">
            <b-select v-model="tipo.Ativo" expanded>
                <option value="">Por favor, selecione...</option>
                <option value="1">Sim</option>
                <option value="0">Não</option>
            </b-select>
        </b-field>

        <hr>
        <b-button type="is-primary pulled-right" @click="$emit('save')"><i class="fa-solid fa-floppy-disk fa-fw mr-1"></i> Salvar</b-button>
    </div>
</template>

<script>

export default {

    props: ['tipo']

}

</script>