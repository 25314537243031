<template>
    <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
        <p class="modal-card-title">Dados</p>
        <button
            type="button"
            class="delete"
            @click="$emit('close')"/>
    </header>
        <section class="modal-card-body">
            <div class="pessoaview">
                        <div class="columns  is-mobile is-desktop">
                    <div class="column">
                        <b-image
                        :src="formProps.fotoUrl"
                        src-fallback=""
                        ></b-image>
                    </div>
                    <div class="column">
                            <b-field label="Data">
                                <b-input :value="formProps.data" readonly ></b-input>
                            </b-field>
                            <b-field label="CPF">
                                <b-input :value="formProps.cpf" readonly v-mask="'###.###.###-##'"></b-input>
                            </b-field>
                            <b-field label="Data de Nascimento">
                                <b-input :value="formProps.nascimento" readonly ></b-input>
                            </b-field>
                    </div>
                    <div class="column">
                                <b-field label="Filial">
                                    <b-input :value="formProps.filial" readonly></b-input>
                                </b-field>
                                <b-field label="Nome">
                                    <b-input :value="formProps.nome" readonly></b-input>
                                </b-field>
                                <b-field label="Resultado Biometria">
                                    <b-input :value="formProps.biometria" readonly></b-input>
                                </b-field>
                    </div>
                </div>
            </div>
        </section>
        <footer class="modal-card-foot is-flex is-justify-content-end">

                        <b-button
                            label="Fechar"
                            type="is-primary"
                            @click="$emit('close')" />
        </footer>
    </div>

</template>


<script>
export default {
    //props: ['cpf', 'nascimento']
    props: {
        formProps: Object
    }
}

</script>