<template>
    <div class="box">
        <h4 class="title is-6">Solicitar bloqueio</h4>
    
        <b-field label="Digite uma justificativa">
            <b-input v-model="justificativa" maxlength="200" type="textarea" placeholder="Digite uma justificativa para o solicitação de bloqueio..."></b-input>
        </b-field>

        <hr>
        <b-button type="is-primary pulled-right" @click="$emit('bloqueio', justificativa)"><i class="fa-solid fa-floppy-disk fa-fw mr-1"></i> Salvar</b-button>
    </div>
</template>

<script>

export default {

    props: ['analise'], 

    data() {
        return {
            justificativa: ''
        }
    }

}

</script>