<template>
    <div class="box">
        <h1 class="h1 has-text-weight-bold mb-4">Revalidação por responsabilidade</h1>
        <p>Tem certeza que deseja alterar o resultado da análise para <strong>{{ analiseValidacao }}</strong>?</p>
        <b-select v-model="alterarResultado" placeholder="Selecione..." class="my-4">
            <option value="1">Sim</option>
            <option value="2">Não</option>
        </b-select>

        <div v-if="alterarResultado == 1">
            <hr/>
            <b-field label="Justificativa *">
                <b-input type="textarea" placeholder="Informe uma justificativa para a mudança. Este campo é obrigatório e precisa estar preenchido." v-model="justificativa"></b-input>
            </b-field>            
        </div>

        <div class="is-flex is-justify-content-space-between is-align-items-center mt-4">
            <b-button @click="cancel" type="is-danger"><i class="fa-regular fa-circle-xmark fa-fw"></i> Cancelar</b-button>
            <div v-if="alterarResultado == 1" class="is-flex is-justify-content-flex-end is-align-items-center">
                <small v-if="isLoading" class="mr-3"><i class="fas fa-spinner fa-pulse fa-fw mr-2"></i> Enviando...</small>
                <b-button @click="save" :disabled="justificativa.length < 5 || isLoading" type="is-success"><i class="fa-regular fa-circle-check fa-fw"></i> Salvar</b-button>
            </div>
        </div>
    </div>
</template>

<script>

import { mapState } from 'vuex'
import flash from "@/mixins/flash";

export default {

    mixins: [flash],
    
    props: {
        analise: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            isLoading: false, 
            alterarResultado: null, 
            justificativa: ''
        }
    },

    methods: {
        save() {
            this.isLoading = true;

            this.$http.post('api/v1/analise/onboarding/' + this.$route.params.id, {
                FlgAprovado: this.analise.FlgAprovado == 1 ? 0 : 1,
                Revalidacao: 1,
                Justificativa: this.justificativa, 
                UsuarioID: this.userId
            }).then(res => {
                if ( ! res.body.success) {
                    this.flash('danger', 'Falha ao salvar a revalidação: ' + res.body.response[Object.keys(res.body.response)[0]]);
                    return false
                }

                this.flash('success', 'Revalidação salva com sucesso');
                this.$emit('updated');

            }).catch(err => {
                console.log(err);
                this.flash('danger', 'Falha interna ao salvar a revalidação, tente novamente mais tarde');
            }).finally(() => {
                this.isLoading = false;
            });
        }, 
        cancel() {
            this.$emit('cancelled');
        }
    },

    computed: {
        analiseValidacao() {
            return this.analise.FlgAprovado == 1 ? 'Validação negativa' : 'Validação positiva';
        }, 
        ...mapState({
        userId(state) {
            return state.auth.user?.id;
        }})
    }

};

</script>