<template>
  <div>
    <div class="columns">
      <div class="column main container">

        <!-- Aviso caso a solicitação de análise possua um pai associado -->
        <div class="notification is-warning" v-if="AnaliseSolicitacaoIDPai != null">
          <i class="fa-solid fa-triangle-exclamation fa-fw me-1"></i> <strong>Atenção: </strong> A solicitação atual está associada a <strong>Solicitação de análise {{ AnaliseSolicitacaoIDPai }}</strong>. Se você realizar um bloqueio nessa análise, isso <strong>afetará a Solicitação de análise {{ AnaliseSolicitacaoIDPai }} e todas as demais análises associadas a ela</strong>. <router-link tag="a" :to="'/dashboard/analiseFraude/' + AnaliseSolicitacaoIDPai" target="_blank">Deseja visualizar a Solicitação de análise {{ AnaliseSolicitacaoIDPai }}</router-link>?
        </div>

        <div class="columns">
          <div class="column is-2">
            <b-field label="Nr. Solicitação">
              <b-input readonly v-model="form.analiseSolicitacaoID"></b-input>
            </b-field>
          </div>
          <div class="column is-3">
            <b-field label="Data">
              <b-input readonly v-model="form.dataCriacao"></b-input>
            </b-field>
          </div>

          <div class="column is-5">
            <b-field label="Filial">
              <b-input readonly v-model="form.filial"></b-input>
            </b-field>
          </div>

          <div class="column">
            <b-field label="Tipo">
              <b-input readonly v-model="form.analiseTipo"></b-input>
            </b-field>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <b-field label="Motivo">
              <b-input readonly v-model="form.motivo"></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Origem">
              <b-input readonly v-model="form.origem"></b-input>
            </b-field>
          </div>

          <div class="column">
            <b-field label="Solicitante">
              <b-input readonly v-model="form.solicitante"></b-input>
            </b-field>
          </div>

          <div class="column">
            <b-field label="E-mail">
              <b-input readonly v-model="form.email"></b-input>
            </b-field>
          </div>

          <div class="column">
            <b-field label="Telefone">
              <b-input readonly v-model="form.telefone"></b-input>
            </b-field>
          </div>
        </div>

        <!--<div class="columns">
            <div class="column">
              <b-field label="Código da Reserva">
                <b-input class="is-clickable" @click.native="abrirReserva" readonly v-model="form.codigoDaReserva"></b-input>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Data Reserva">
                <b-input readonly v-model="form.dataReserva"></b-input>
              </b-field>
            </div>

            <div class="column">
              <b-field label="Data Retirada">
                <b-input readonly v-model="form.dataRetidada"></b-input>
              </b-field>
            </div>

            <div class="column">
              <b-field label="Canal">
                <b-input readonly v-model="form.canal"></b-input>
              </b-field>
            </div>

            <div class="column is-4">
              <b-field label="Detalhes do Veículo / Grupo Reservado">
                <b-input readonly v-model="form.detalhesVeiculo"></b-input>
              </b-field>
            </div>
          </div>-->
        
        <analise-cliente v-if="form.analiseSolicitacaoID != null" :analise="analise"></analise-cliente>
        <table-reservas></table-reservas>
        
        <analise-associada v-if="form.analiseSolicitacaoID != null" :pai="form.analiseSolicitacaoID"></analise-associada>
        <analise-checklist v-if="analise != null" :analise="analise"></analise-checklist>

        <div class="columns">

          <!-- Coluna esquerda -->
          <div class="column">
            <div class="columns">
              <div class="column is-4">
                <b-field label="Documento">
                  <b-input class="is-clickable" @click.native="abrirPessoa" readonly v-model="form.cpf"></b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Nome">
                  <b-input readonly v-model="form.nome"></b-input>
                </b-field>
              </div>
            </div>
            
            <b-field class="mb-5" label="Tipo">
              <b-input readonly v-model="form.tipo"></b-input>
            </b-field>

            <b-field label="Observação da Solicitação">
              <b-input maxlength="200" readonly v-model="form.observacaoSolicitacao" type="textarea"></b-input>
            </b-field>

          </div>

          <!-- Coluna direita -->
          <div class="column">
            <div class="columns">
              <div class="column is-4">
                <b-field class="pr-0 pl-0 is-align-self-flex-end" label="Conclusão da Análise">
                  <b-input v-if="this.form.dataConclusao || userId !== this.form.analiseUsuarioID" readonly v-model="form.conclusaoAnalise"></b-input>
                  <b-select @input="inserirMotivoDesbloqueio($event)" v-model="form.conclusaoAnalise" v-else placeholder="Selecione o motivo" expanded>
                    <option v-for="option in form.conclusaoAnaliseSelect" :value="option.AnaliseConclusaoID" :key="option.AnaliseConclusaoID">
                      {{ option.Descricao }}
                    </option>
                  </b-select>
                </b-field>
              </div>

              <div class="column">
                <b-field label="Bloqueio / Desbloqueio do Cliente">
                  <b-input readonly v-model="form.bloqueioDesbloqueioCliente"></b-input>
                </b-field>
              </div>
            </div>

            <b-field label="Observação da Conclusão">
              <b-input v-if=" ! form.dataConclusao && this.form.analiseStatusID === 2 && userId === this.form.analiseUsuarioID" maxlength="500" v-model="form.observacaoConclusao" type="textarea" class="field-observacao"></b-input>
              <b-input v-else readonly maxlength="500" v-model="form.observacaoConclusao" type="textarea" class="field-observacao"></b-input>
            </b-field>
          </div>
        </div>
        
        <div class="columns">
          <div class="column level-left">
            <b-field class="level-left" label="">
              <b-button icon-left="paperclip" type="is-primary" @click="abrirAnexoAnaliseModal">Anexos</b-button>
            </b-field>
          </div>
          <div class="column level-right">
            <b-field class="level-right" label="">
              <div>
                <b-button
                  v-if="
                    this.form.analiseStatusID === 2 &&
                    userId === this.form.analiseUsuarioID
                  "
                  class="mr-2"
                  type="is-primary"
                  @click="concluirAnalise"
                  >Salvar</b-button
                >
                <router-link
                  :to="{ name: 'analiseFraude', query: { filtros: true } }"
                >
                  <b-button type="is-primary" outlined>Fechar</b-button>
                </router-link>
              </div>
            </b-field>
          </div>
        </div>
        <b-modal
          v-model="isComponentModalActive"
          trap-focus
          :destroy-on-hide="false"
          aria-role="dialog"
          aria-label="Example Modal"
          close-button-aria-label="Close"
          :width="960"
          aria-modal
        >
          <template #default="props">
            <anexo-analise-modal
              v-bind:formProps="formProps"
              @close="props.close"
            ></anexo-analise-modal>
          </template>
        </b-modal>
      </div>
    </div>
    <b-loading
      :is-full-page="true"
      v-model="isLoading"
      :can-cancel="false"
    ></b-loading>
  </div>
</template>

<style>
  .field-observacao textarea {
    height: 216px;
  }
</style>

<script>
import Table from "../components/Table.vue";
import SidebarComponent from "@/components/SidebarComponent.vue"; // @ is an alias to /src
import NavbarComponent from "@/components/NavbarComponent.vue"; // @ is an alias to /src
import { mapState } from "vuex";
import AnexoAnaliseModal from "@/components/AnexoAnaliseModal.vue";
import flash from "@/mixins/flash.js";
import services from "@/http";
import * as dayjs from "dayjs";
import TableReservas from "@/modules/analise/solicitacao/components/TableReservas.vue";
import AnaliseAssociada from "@/modules/analise/solicitacao/components/AnaliseAssociada.vue";
import AnaliseCliente from "@/modules/dashboard/analiseFraude/components/AnaliseCliente.vue";
import AnaliseChecklist from "@/modules/analise/solicitacao/components/AnaliseChecklist.vue";


export default {
  mixins: [flash],

  components: {
    Table,
    AnexoAnaliseModal,
    "nav-manage": SidebarComponent,
    navbar: NavbarComponent,
    TableReservas,
    AnaliseAssociada,
    AnaliseCliente,
    AnaliseChecklist,
  },
  data() {
    return {

      analise: null, 

      // Solicitação de análise pai
      AnaliseSolicitacaoIDPai: null,
      AnaliseOnboardingID: null, 

      analise: null, 

      formProps: {
        AnaliseSolicitacaoID: this.$route.params.id,
        DataConclusao: null,
        AnaliseUsuarioID: null,
      },
      isComponentModalActive: false,
      isLoading: false,
      checklistSelected: [], 
      form: {
        pessoasBloqueioTipo: null,
        conclusaoAnaliseSelect: [],
        analiseSolicitacaoID: null,
        dataCriacao: null,
        dataConclusao: null,
        filial: null,
        analiseTipo: null,
        analiseStatusID: null,
        motivo: null,
        origem: null,
        solicitante: null,
        email: null,
        telefone: null,
        codigoDaReserva: null,
        dataReserva: null,
        dataRetidada: null,
        canal: null,
        detalhesVeiculo: null,
        cpf: null,
        nome: null,
        conclusaoAnalise: null,
        bloqueioDesbloqueioCliente: null,
        observacaoSolicitacao: null,
        observacaoConclusao: null,
        analiseUsuarioID: null,
        reservaUrl: null,
        pessoaUrl: null,
        tipo: null
      },
    };
  },
  methods: {
    abrirReserva() {
      window.open(this.form.reservaUrl, "_blank");
    },
    abrirPessoa() {
      window.open(this.form.pessoaUrl, "_blank");
    },
    loadTipoAnalises(removerItens = []) {

      this.isLoading = true

      services.analiseFraude.analiseConclusao().then(({ data }) => {

        let conclusoes = data.filter((item) => {
          return ! removerItens.includes(item.AnaliseConclusaoID);
        });

        this.form.conclusaoAnaliseSelect = conclusoes;
      });

      services.analiseFraude.pessoasBloqueioTipo().then(({ data }) => {
        this.form.pessoasBloqueioTipo = data;
        this.isLoading = false
      });
    },
    inserirMotivoDesbloqueio(id) {
      let filtroConclusao = this.form.conclusaoAnaliseSelect.filter((item) => {
        return item.AnaliseConclusaoID === id;
      });

      let filtroPessoas = this.form.pessoasBloqueioTipo.filter((item) => {
        return (
          item.PessoasBloqueioTipoID ===
          filtroConclusao[0].PessoasBloqueioTipoID
        );
      });

      this.form.bloqueioDesbloqueioCliente = null;
      if (filtroPessoas.length > 0) {
        this.form.bloqueioDesbloqueioCliente = filtroPessoas[0].Descricao;
      }
    },
    abrirAnexoAnaliseModal() {
      this.isComponentModalActive = true;
    },

    concluirAnalise() {

      if (this.form.conclusaoAnalise == undefined) {
        this.flash("danger", "É obrigatório preencher a conclusão da análise")
        return 
      }

      if (this.form.observacaoConclusao == null || this.form.observacaoConclusao == "") {
        this.flash("danger", "É obrigatório preencher a observação referente a conclusão da análise")
        return
      }

      if (this.form.conclusaoAnalise && this.form.observacaoConclusao == "") {
        this.flash("danger", "É obrigatório preencher a conclusão da observação.");
        return;
      }

      if (this.form.conclusaoAnalise && this.checklistSelected.length == 0) {
        this.flash("danger", "É obrigatório selecionar ao menos um item do checklist para concluir a análise");
        return;
      }

      if (this.form.observacaoConclusao.length > 500) {
        alert("Observação da Conclusão tem o tamanho máximo de 500 caracteres.");
        return;
      }

      const params = {
        AnaliseSolicitacaoID: this.form.analiseSolicitacaoID,
        AnaliseConclusaoID: this.form.conclusaoAnalise,
        ObsConclusao: this.form.observacaoConclusao,
        AnaliseConclusaoChecklist: this.checklistSelected,
        // UsuarioID: this.userId
      };
      this.isLoading = true;

      services.analiseFraude
        .concluirAnalise(params)
        .then((data) => {
          if (data.body.success) {
            this.loadAsyncData();
            this.flash("success", data.body.msg);
          } else {
            this.flash("danger", data.body.msg);
            this.isLoading = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
        });
    },
    loadAsyncData() {
      this.isLoading = true;
      services.analiseFraude
        .analiseFraudeId({ id: this.$route.params.id })
        .then((data) => {
          const body = data.body;

          this.AnaliseSolicitacaoIDPai = body.response.AnaliseSolicitacaoIDPai;
          this.AnaliseOnboardingID = body.response.AnaliseOnboardingID;
          this.form.tipo = body.response.PessoaTipo;

          if (!body.success) {
            this.flash("danger", "Não foi possível visualizar a solicitação.");
            return;
          }

          // Adicionamos o retorno da requisição, que se refere
          // a análise, em uma variável
          this.analise = body.response;

          const {
            AnaliseSolicitacaoID,
            DataCriacao,
            filial,
            analise_tipo,
            analise_motivo,
            analise_origem,
            analise_conclusao,
            Email,
            Telefone,
            reserva,
            pessoa,
            usuario,
            ObsConclusao,
            DataConclusao,
            AnaliseUsuarioID,
            AnaliseStatusID,
            ObsSolicitacao,
          } = body.response;

          (this.form.dataConclusao = DataConclusao
            ? new dayjs(DataCriacao).format("DD/MM/YYYY HH:mm:ss")
            : DataConclusao),
            (this.form.analiseSolicitacaoID = AnaliseSolicitacaoID);
          this.form.dataCriacao = new dayjs(DataCriacao).format(
            "DD/MM/YYYY HH:mm:ss"
          );
          this.form.filial = filial?.Filial;
          this.form.analiseTipo = analise_tipo?.Descricao;
          this.form.motivo = analise_motivo?.Descricao;
          this.form.origem = analise_origem?.Descricao;
          this.form.email = Email;
          this.form.telefone = Telefone;
          (this.form.analiseUsuarioID = AnaliseUsuarioID),
            (this.form.analiseStatusID = AnaliseStatusID),
            (this.form.codigoDaReserva = reserva?.CodigoReserva);
          this.form.dataReserva = reserva?.DataReserva
            ? new dayjs(reserva?.DataReserva).format("DD/MM/YYYY HH:mm:ss")
            : null;
          this.form.dataRetidada = reserva?.DataRetirada
            ? new dayjs(reserva?.DataRetirada).format("DD/MM/YYYY HH:mm:ss")
            : null;
          this.form.canal = reserva?.canal?.Canal;
          this.form.reservaUrl = reserva?.Url;
          this.form.detalhesVeiculo =
            (reserva?.grupo?.GrupoID ? reserva?.grupo?.GrupoID : "") +
            " " +
            (reserva?.grupo?.ACRIS ? reserva?.grupo?.ACRIS : "") +
            " " +
            (reserva?.grupo?.Modelos ? reserva?.grupo?.Modelos : "");
          this.form.observacaoConclusao = ObsConclusao;
          this.form.observacaoSolicitacao = ObsSolicitacao;
          this.form.nome = pessoa?.Pessoa;
          this.form.pessoaUrl = pessoa?.Url;
          this.form.cpf = pessoa?.CPFCNPJ.replace(
            /(\d{3})(\d{3})(\d{3})(\d{2})/,
            "$1.$2.$3-$4"
          );
          this.form.solicitante = usuario?.Nome;
          this.form.conclusaoAnalise = analise_conclusao?.Descricao;
          this.form.bloqueioDesbloqueioCliente =
            analise_conclusao?.pessoa_bloqueio_tipo?.Descricao;

          this.formProps.DataConclusao = this.form.dataConclusao;
          this.formProps.AnaliseUsuarioID = this.form.analiseUsuarioID;
        })
        .finally((data) => {
          this.isLoading = false;
          this.$root.$emit("resource-loaded");
        });
    }
  },
  computed: {
    ...mapState({
      userId(state) {
        return state.auth.user?.id;
      },
    }),
  },
  mounted() {
    this.loadAsyncData();

    this.$root.$on('checklist-updated', (params) => {
      this.checklistSelected = params
    })

    this.$root.$on("analise-associada-loaded", (params) => {

      // let removerItens = []
      // if (params.aprovadas.length < params.analises.length) {
      //   removerItens = [1, 2]
      // }

      this.loadTipoAnalises()
    });
  },
};
</script>
