import { services as auth } from '@/modules/auth';
import { services as creddefense } from '@/modules/dashboard/creddefense'
import { services as analiseFraude } from  '@/modules/dashboard/analiseFraude'
import { services as operacaoEvento } from '@/modules/operacao/evento'
import { services as operacaoEventoStatus } from '@/modules/operacao/statusEvento'
import { services as operacaoPrestador } from '@/modules/operacao/prestador'
import { services as operacaoTipo } from '@/modules/operacao/tipo'
import { services as unidadeNegocio } from '@/modules/unidade/negocio'
import { services as notificacaoMovimentacao } from '@/modules/dashboard/notificacaoMovimentacao'
import { services as midia } from '@/modules/midia'

export default {
    auth,
    creddefense,
    analiseFraude, 
    unidadeNegocio, 
    operacaoEvento, 
    operacaoTipo,
    notificacaoMovimentacao,
    operacaoEventoStatus,
    operacaoPrestador, 
    midia
}