<template>
  <layout>
    <page-title subtitle="Listando informações da análise">
        Painel de solicitação de análise
    </page-title>
    <hr>
    <ViewId/>
  </layout>
</template>

<script>
  import ViewId from '../components/ViewId.vue';

  export default {
    components: { ViewId }
  }
</script>
