import Vue from 'vue';
import VueResource from 'vue-resource';

import services from './services';

Vue.use(VueResource);

const http = Vue.http

http.options.root = process.env.VUE_APP_BFF_URL;

Object.keys(services).map(service => {
  services[service] = Vue.resource('',{}, services[service])
})

const setBearerToken = token => {
  http.headers.common['Authorization' ] = `Bearer ${token}`;
}

http.interceptors.push(function(request, next){
  next(function (response) {
    if (response.status === 401) {
      window.location = '/?token=false'
    }
  })
});

export { http, setBearerToken }
export default services;