<template>
    <layout>

        <!-- Loading -->
        <b-loading v-model="isLoading"></b-loading>
        
        <div v-if="resource">
            <page-title subtitle="Tipos de operações">
                {{ resource.Descricao }}

                <div slot="actions">
                    <b-button @click="isShowingModalStatus = ! isShowingModalStatus" type="is-primary is-light mr-2"><i class="fas fa-link mr-2"></i> Vincular status</b-button>
                    <b-button @click="isShowingModalStatusRecuperacao = ! isShowingModalStatusRecuperacao" type="is-primary is-light"><i class="fas fa-link mr-2"></i> Vincular status de recuperação</b-button>
                </div>
            </page-title>
            
            <card title="Informações do tipo de operação">
                <div class="columns">
                    <div class="column is-four-fifths">
                        <attribute title="Descrição">
                            {{ resource.Descricao }}
                        </attribute>
                    </div>
                    <div class="column">
                        <attribute title="Ativo?">
                            {{ resource.Ativo ? 'Sim' : 'Não' }}
                        </attribute>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <attribute title="Adicionado em">
                            {{ new Date(resource.created_at) | dateFormat('DD/MM/YYYY') }}
                        </attribute>
                    </div>
                    <div class="column">
                        <attribute title="Alterado em">
                            {{ new Date(resource.updated_at) | dateFormat('DD/MM/YYYY') }}
                        </attribute>
                    </div>
                </div>
            </card>
        
            <!-- Status da operação -->
            <card v-if="status" title="Status vinculados" :subtitle="status.length + ' status vinculado(s)'">
            
                <b-table 
                    v-if="status.length > 0"
                    :data="status" 
                    :striped="true" 
                    :hoverable="true" >

                    <b-table-column label="Unidade de negócio" v-slot="props">
                        {{ props.row.unidade_negocio.UnidadeNegocio }}
                    </b-table-column>
                    
                    <b-table-column label="Status" v-slot="props">
                        <strong>{{ props.row.frota_status.Status }}</strong>
                    </b-table-column>

                    <b-table-column width="100" label="Ações" centered v-slot="props">
                        <b-tooltip label="Desvincular" type="is-dark">
                            <a href="#" @click.prevent="detach(props.index)"><i class="fa-solid fa-link-slash fa-fw"></i></a>
                        </b-tooltip>
                    </b-table-column>
                </b-table>

                <div v-else>
                    <div class="notification is-warning">
                        <i class="fa-solid fa-circle-info fa-fw mr-1"></i> Nenhuma vinculação encontrada para esse tipo de operação.
                    </div>
                </div>
            </card>

            <!-- Status de recuperação -->
            <card title="Status de recuperação associados" v-if="status">
                
                <div v-if="status.length > 0">
                    <div v-for="(item, index) in status" v-bind:key="index">

                        <span class="is-block has-text-weight-bold"><i class="fa-solid fa-list-ol"></i> {{ item.unidade_negocio.UnidadeNegocio }} - {{ item.frota_status.Status }}</span>

                        <b-table 
                            class="mb-5"
                            v-if="item.status_recuperacao.length > 0"
                            :data="item.status_recuperacao" 
                            :striped="true" 
                            :hoverable="true" >

                            <b-table-column v-slot="props">
                                {{ props.row.status.Status }}
                            </b-table-column>

                            <b-table-column width="100" centered v-slot="props">
                                <b-tooltip label="Desvincular" type="is-dark">
                                    <a href="#" @click.prevent="detachStatusRecuperacao(item, props.index)"><i class="fa-solid fa-link-slash fa-fw"></i></a>
                                </b-tooltip>
                            </b-table-column>
                        
                        </b-table>

                        <div v-else class="notification mt-4 mb-5">
                            <i class="fa-solid fa-circle-info fa-fw mr-1"></i> Nenhuma vinculação encontrada para esse tipo de operação.
                        </div>
                    </div>
                </div>

                <div v-else>
                    <div class="notification is-warning">
                        <i class="fa-solid fa-circle-info fa-fw mr-1"></i> Não existem status para serem associados a um status de recuperação. Para visualizar este card, primeiramente vincule status a esse tipo de operação, utilizando o menu "Vincular status"
                    </div>
                </div>
            </card>

            <!-- Formulário de status -->
            <b-modal v-model="isShowingModalStatus" :width="480" scroll="keep">
                <status-form @sync="syncStatus" :tipo="resource"></status-form>
            </b-modal>

            <!-- Formulário de status para recuperação -->
            <b-modal v-model="isShowingModalStatusRecuperacao" :width="480">
                <status-recuperacao-form @syncStatusRecuperacao="syncStatusRecuperacao" :status="status"></status-recuperacao-form>
            </b-modal>

        </div>

        <!-- Formulário de status -->
        <b-modal v-model="isFormModalActive" :width="480" scroll="keep">
            <status-form @sync="syncStatus" :tipo="resource"></status-form>
        </b-modal>
        
    </layout>
</template>

<script>
    import flash from '@/mixins/flash'
    import services from '@/http'
    import Layout from '@/components/Layout.vue'
    import StatusForm from '@/modules/operacao/tipo/components/StatusForm.vue'
    import StatusRecuperacaoForm from '@/modules/operacao/tipo/components/StatusRecuperacaoForm.vue'

    export default {
        components: {
            Layout, 
            StatusForm, 
            StatusRecuperacaoForm
        },
        mixins: [flash], 
        data() {
            return {
                isLoading: true, 
                id: this.$route.params.id,
                resource: null, 
                status: null, 

                isShowingModalStatus: false, 
                isShowingModalStatusRecuperacao: false
            }
        }, 
        methods: {
            fetch() {
                services.operacaoTipo.show({ tipo: this.id, withStatus: 1 }).then(res => {
                    this.resource = res.body.response;
                    this.fetchStatus()
                });
            }, 
            fetchStatus() {
                this.$http.get('api/v1/status/frota/operacao/' + this.id).then(res => {
                    if (res.body.success) {
                        this.status = res.body.response
                        this.isLoading = false
                    } else {
                        this.flash('danger', 'Não foi possível retornar os status do tipo de operação');
                    }
                })
            }, 
            syncStatus(event) {
                this.$http.post('api/v1/status/frota/operacao/' + this.id + '/sync', { status: event.status }).then(res => {
                    
                    if (res.body.success) {
                        this.isShowingModalStatus = false;
                        this.fetchStatus();
                        return this.flash('success', res.body.response.message);
                    }

                    this.flash('danger', 'Erro ao remover status da operação');
                })
            }, 
            syncStatusRecuperacao(event) {

                this.$http.post('api/v1/status/frota/operacao/' + this.id + '/sync/status/recuperacao', { status: event.status }).then(res => {
                    
                    if (res.body.success) {
                        this.isLoading = true
                        this.isShowingModalStatusRecuperacao = false
                        this.fetch()
                        return this.flash('success', res.body.response.msg);
                    }

                    this.flash('danger', 'Erro ao sincronizar os status');
                })
            }, 
            detach(index) {
                this.isLoading = true
                let status = this.status[index];

                this.$http.post('api/v1/status/frota/operacao/' + this.id + '/detach', { TipoOperacaoStatusMovID: status.TipoOperacaoStatusMovID }).then(res => {
                    
                    if (res.body.success) {
                        this.flash('warning', res.body.response.message);
                        this.fetchStatus();
                    } else {
                        this.flash('danger', 'Erro ao remover status da operação');
                    }
                }).finally(function(res) {
                    this.isLoading = false
                })
            }, 
            detachStatusRecuperacao(listStatus, index) {

                this.isLoading = true
                const pluck = (arr, key) => arr.map(i => i[key]);
                listStatus.status_recuperacao.splice(index, 1)

                let payload = { status: [] }
                payload.status[listStatus.TipoOperacaoStatusMovID] = pluck(listStatus.status_recuperacao, 'StatusID')

                this.syncStatusRecuperacao(payload)
            }
        }, 
        mounted() {
            this.fetch()
        }
    }

</script>
