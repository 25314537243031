<template>
    <div class="box my-5">
        <div class="is-flex is-justify-content-space-between is-align-content-center">
            <div>
                <h5 v-if="title" class="title is-5">{{ title }}</h5>
                <h6 v-if="subtitle" class="subtitle is-6">{{ subtitle }}</h6>
            </div>

            <div v-if="canCollapse">
                <button v-if=" ! isOpen" @click="isOpen = !isOpen" class="button is-warning is-light is-small is-link"><i class="fa-solid fa-arrow-down fa-fw mr-1"></i> Expandir</button>
                <button v-else @click="isOpen = !isOpen" class="button is-warning is-light is-small is-link"><i class="fa-solid fa-arrow-up fa-fw mr-1"></i> Retrair</button>
            </div>
        </div>

        <b-collapse animation="slide" v-model="isOpen">
            <div :class="{ 'mt-5': title }">
                <slot></slot>
            </div>
        </b-collapse>
      
    </div>
</template>

<script>

export default {

    props: {
        title: {
            type: String, 
        }, 
        subtitle: {
            type: String
        }, 
        canCollapse: {
            type: Boolean, 
            default: true
        }
    }, 

    data() {
        return {
            isOpen: true
        }
    }

}

</script>