<template>
    <layout>
        <page-title subtitle="Preencha o formulário abaixo para adicionar uma nova solicitação de análise">
            Adicionar - Análise de Solicitações
        </page-title>
        <hr>
        
        <AnaliseSolicitacaoForm/>
    </layout>

</template>

<script>

import AnaliseSolicitacaoForm from '../components/AnaliseSolicitacaoForm.vue';

export default {

    components: {
        AnaliseSolicitacaoForm
    }

}

</script>
