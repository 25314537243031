import IndexView from './pages/IndexView';

export default [
    {
        path: '/operacao/eventoStatus',
        name: 'operacao.statusEvento',
        component: IndexView,
        meta: {
            permission: 'operacao.statusEvento', 
            keepAlive: false
        }
    }
];