<template>
    <card title="Histórico de Revalidações por Responsabilidade">
        
        <div class="columns">
            <div class="column">

                <div v-if="revalidacoes.length > 0">
                    <table class="table is-fullwidth is-hoverable">
                        <thead>
                            <tr>
                                <th class="is-size-7">Data e hora</th>
                                <th class="is-size-7">Usuário</th>
                                <th class="is-size-7">Sit. antes</th>
                                <th class="is-size-7">Sit. depois</th>
                                <th class="is-size-7">Justificativa</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="revalidacao in revalidacoes">
                                <td>
                                    {{ new Date(revalidacao.Data).toLocaleDateString('pt-BR', { year: 'numeric', month: '2-digit', day: '2-digit' }) }}
                                    {{ new Date(revalidacao.Data).toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' }) }}
                                </td>
                                <td>{{ revalidacao.usuario ? revalidacao.usuario.Nome : '(Sem nome associado)' }}</td>
                                <td>
                                    <span v-if="revalidacao.FlgAprovadoAntes == 1" class="tag is-success is-light"><i class="fa-regular fa-circle-check fa-fw mr-2"></i> Validação positiva</span>
                                    <span v-else class="tag is-danger is-light"><i class="fa-regular fa-circle-xmark fa-fw mr-2"></i> Validação negativa</span>
                                </td>
                                <td>
                                    <span v-if="revalidacao.FlgAprovadoDepois == 1" class="tag is-success is-light"><i class="fa-regular fa-circle-check fa-fw mr-2"></i> Validação positiva</span>
                                    <span v-else class="tag is-danger is-light"><i class="fa-regular fa-circle-xmark fa-fw mr-2"></i> Validação negativa</span>
                                </td>
                                <td>{{ revalidacao.Justificativa }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div v-else>
                    <div class="notification is-info">
                        <p class="mb-0">Não há revalidações para este onboarding</p>
                    </div>
                </div>

            </div>
        </div>
    </card>  
</template>

<script>

import { mapState } from 'vuex'
import flash from "@/mixins/flash";

export default {
    
    mixins: [flash],
    
    props: {
        revalidacoes: {
            type: Array,
            required: true
        }
    },
    
};

</script>