// Components
import Forbidden from './pages/forbidden/ForbiddenView.vue';
import NotFound from './pages/not-found/NotFoundView.vue';
export default [
    {
        path: '/',
        name: 'login',
        beforeEnter: () => {
            window.location.href = `${process.env.VUE_APP_AUTH_URL}?redirect_to=${process.env.VUE_APP_REDIRECT_TO_URL}`;
        },
    },
    {
        path: '/sso',
        name: 'sso',
        component: () => import('./pages/SSOView.vue')
    },
    {
        path: '/forbidden',
        name: 'forbidden',
        meta: {
            title: "Não autorizado"
        },
        component: Forbidden,
    },
    {
        path: '*',
        name: 'not-found',
        component: NotFound
    }
]
