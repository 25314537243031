<template>
    <layout>
      <page-title subtitle="Manutenção e gestão dos tipos de análise utilizados nas solicitações de análise">
        Tipos de análise - Solicitações de análise
  
        <div slot="actions">
          <b-button @click="isFormModalActive = true" type="is-primary is-light"><i class="fa-solid fa-plus fa-fw mr-1"></i> Novo tipo</b-button>
        </div>
      </page-title>
      <hr />
  
      <data-table ref="dataTable" @edit="edit" @remove="remove"></data-table>
  
      <!-- Formulário de gestão -->
      <b-modal @close="clear" v-model="isFormModalActive" :width="640" scroll="keep">
        <analise-tipo-form @save="save" :tipo="tipo" ref="analiseTipoForm"></analise-tipo-form>
      </b-modal>
  
      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
      
    </layout>
  </template>
  
  <script>
  
  import flash from '@/mixins/flash';
  
  export default {
  
    mixins: [flash],
  
    components: {
      DataTable: () => import('@/modules/analise/tipo/components/DataTable.vue'),
      AnaliseTipoForm: () => import('@/modules/analise/tipo/components/AnaliseTipoForm.vue')
    },
  
    data() {
      return {
        isLoading: false, 
        isFormModalActive: false,
        tipo: {}
      }
    },
  
    methods: {
  
      edit(params) {
        this.tipo = Object.assign({}, params)
        this.isFormModalActive = true
      },
  
      clear() {
        this.tipo = {}
      },
  
      save(params) {
        this.tipo = params
        this.tipo.AnaliseTipoID === undefined ? this.store(params) : this.update(params)
      }, 
  
      update(params) {
  
        this.isLoading = true
  
        this.$http.post('api/v1/analise/tipo/' + params.AnaliseTipoID, params).then((response) => {
  
          if (response.body.success) {
            this.$refs['dataTable'].fetch();
            this.isFormModalActive = false;
            this.flash('success', 'Tipo de análise atualizado com sucesso')
            this.clear()
          } else {
            this.flash('danger', response.body.response[Object.keys(response.body.response)[0]])
          }
  
          this.isLoading = false
  
        }).catch((error) => {
          console.log(error)
          this.flash('danger', 'Falha ao atualizar o tipo da análise')
        });
      }, 
  
      store(params) {
  
        this.isLoading = true
  
        this.$http.post('api/v1/analise/tipo', params).then((response) => {
  
          if (response.body.success) {
            this.$refs['dataTable'].fetch();
            this.isFormModalActive = false;
            this.flash('success', 'Tipo de análise salvo com sucesso')
            this.clear()
          } else {
            this.flash('danger', response.body.response[Object.keys(response.body.response)[0]])
          }
  
          this.isLoading = false
  
        }).catch((error) => {
          console.log(error)
          this.flash('danger', 'Falha ao salvar o tipo da análise')
        });
      }, 
  
      remove(params) {
  
        this.isLoading = true
  
        let confirmacao = confirm('Deseja realmente excluir o tipo da análise?')
  
        if ( ! confirmacao) {
          this.isLoading = false
          return
        }
        
        this.$http.delete('api/v1/analise/tipo/' + params.AnaliseTipoID).then((response) => {
  
          if (response.body.success) {
            this.$refs['dataTable'].fetch();
            this.flash('success', 'Tipo de análise removido com sucesso')
            this.clear()
          } else {
            this.flash('danger', response.body.response.msg)
          }
  
          this.isLoading = false
  
          }).catch((error) => {
            console.log(error)
            this.flash('danger', 'Falha ao remover o tipo da análise')
          });
      }
    },
  
  };
  </script>
  