<template>
  <layout>
    <page-title subtitle="Exibindo análise">
        Análise de Solicitações
    </page-title>

    <div class="mt-5">
      <ViewId/>
    </div>
  </layout>
</template>

<script>
import ViewId from '../components/ViewId.vue';

export default {
  components: {
    ViewId,
  }
}


</script>
