<template>
  <div>
    <b-loading v-model="isLoading"></b-loading>

    <div class="columns">
      <div class="column">
        <attribute title="Nro. Solic.">
          {{ analise.AnaliseSolicitacaoID }}
        </attribute>
      </div>
      <div class="column">
        <attribute title="Data de criação">
          {{ new Date(analise.DataCriacao) | dateFormat("DD/MM/YYYY HH:mm") }}
        </attribute>
      </div>
      <div class="column is-6">
        <attribute title="Filial">
          {{
            analise.filial ? analise.filial.Filial : "(Sem filial atribuída)"
          }}
        </attribute>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <attribute title="Tipo">
          {{ analise.analise_tipo.Descricao }}
        </attribute>
      </div>
      <div class="column">
        <attribute title="Origem">
          {{ analise.analise_origem.Descricao }}
        </attribute>
      </div>
      <div class="column">
        <attribute title="Status">
          {{ analise.analise_status.Descricao }}
        </attribute>
      </div>
      <div class="column is-5">
        <attribute title="Motivo">
          {{ analise.analise_motivo.Descricao }}
        </attribute>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <attribute title="Solicitante">
          {{ analise.usuario.Nome }}
        </attribute>
      </div>
      <div class="column">
        <attribute title="E-mail">
          <a :href="'mailto:' + analise.Email">{{ analise.Email }}</a>
        </attribute>
      </div>
      <div class="column">
        <attribute title="Telefone">
          {{ analise.Telefone }}
        </attribute>
      </div>
    </div>

    <div class="columns">
      <div class="column is-4">
        <attribute title="Documento">
          <a :href="analise.pessoa.Url" target="_blank">{{ analise.pessoaCPFCNPJ ? analise.pessoaCPFCNPJ : '(Não informado)' }}</a>
        </attribute>
      </div>
      <div class="column">
        <attribute title="Nome">
          {{ analise.pessoa ? analise.pessoa.Pessoa : "(Não informada)" }}
        </attribute>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <attribute title="Tipo">
          {{ analise.PessoaTipo }}
        </attribute>
      </div>
    </div>

    <!-- Reservas -->
    <TableReservasVue />

    <!-- Análises associadas -->
    <AnaliseAssociada
      v-if="analise.AnaliseSolicitacaoID != null"
      :pai="analise.AnaliseSolicitacaoID"
      link="/analise/solicitacoes/"
    ></AnaliseAssociada>

    <card title="Dados da análise" subtitle="Informações da análise realizada">
      <div class="columns">
        <div class="column">
          <attribute title="Analista">
            {{ analise.analista ? analise.analista.Nome : "(Sem analista)" }}
          </attribute>
        </div>
        <div class="column is-3">
          <attribute title="Tempo">
            {{ analise.Tempo }}
          </attribute>
        </div>
        <div class="column">
          <attribute title="Conclusão da análise">
            {{
              analise.analise_conclusao
                ? analise.analise_conclusao.Descricao
                : "-"
            }}
          </attribute>
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <!-- Checagem da análise -->
          <attribute v-if="analise.analise_conclusao" title="Bloqueio/Desbloqueio do cliente">
            {{ analise.analise_conclusao ? analise.analise_conclusao.Descricao : '-' }}
            <span v-if="analise.analise_conclusao.PessoasBloqueioTipoID"> - {{ analise.analise_conclusao.PessoasBloqueioTipoID.Descricao }}</span>
          </attribute>
          <attribute v-else title="Bloqueio/Desbloqueio do cliente">
            -
          </attribute>
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <attribute title="Observações da solicitação">
            {{ analise.ObsSolicitacao }}
          </attribute>
        </div>
        <div class="column">
          <attribute title="Observações da conclusão">
            {{ analise.ObsConclusao }}
          </attribute>
        </div>
      </div>
      <div v-if="analise.anexos" class="columns">
        <div class="column">
          <b-field class="level-left" label="">
            <b-button
              icon-left="paperclip"
              type="is-primary"
              @click="abrirAnexoAnaliseModal"
              >Anexos</b-button
            >
          </b-field>
          <b-modal
            v-model="isComponentModalAnexoActive"
            trap-focus
            :destroy-on-hide="false"
            aria-role="dialog"
            aria-label="Example Modal"
            close-button-aria-label="Close"
            :width="960"
            aria-modal
          >
            <template #default="props">
              <anexo-analise-modal
                v-bind:formProps="formProps"
                @close="props.close"
              ></anexo-analise-modal>
            </template>
          </b-modal>
        </div>
      </div>
    </card>

    <!-- Card para solicitação de bloqueio -->
    <div v-if="analise.AnaliseSolicitacaoID != null">
      <div v-if="allowBloqueio">
        <card v-if="analise.analise_conclusao.AnaliseConclusaoID == 2" title="Atenção">
          <div class="notification is-danger">
            <span class="is-size-5"
              ><i class="fa-solid fa-circle-exclamation mr-1"></i> Essa
              solicitação foi concluída com o status
              <strong>{{ analise.analise_conclusao.Descricao }}</strong
              >. Deseja solicitar o bloqueio?</span
            >
          </div>
          <hr />
          <b-button @click="openSolicitarBloqueioModal" class="is-primary mr-2"
            >Sim</b-button
          >
          <router-link
            :to="{ name: 'analise.solicitacoes', query: { filtros: true } }"
          >
            <b-button class="is-danger">Não</b-button>
          </router-link>
        </card>
        <card v-else title="Deseja solicitar o bloqueio?">
          <hr />
          <b-button @click="openSolicitarBloqueioModal" class="is-primary mr-2"
            >Sim</b-button
          >
          <router-link
            :to="{ name: 'analise.solicitacoes', query: { filtros: true } }"
          >
            <b-button class="is-danger">Não</b-button>
          </router-link>
        </card>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <router-link
          :to="{ name: 'analise.solicitacoes', query: { filtros: true } }"
        >
          <b-button type="is-primary is-large" outlined
            ><i class="fa-solid fa-circle-xmark fa-fw"></i> Fechar</b-button
          >
        </router-link>
      </div>
      <div v-can="'acompanhamento.validacao.biometrica'">
        <div class="column" v-if="analise.AnaliseOnboardingID != null">
          <router-link :to="{name: 'acompanhamento.validacao.biometrica.show', params: { id: analise.AnaliseOnboardingID }}">
            <b-button type="is-primary is-large" outlined><i class="fa-regular fa-face-smile fa-fw"></i> Consulta biométrica</b-button>
          </router-link>
        </div>
      </div>
    </div>

    <b-modal
      v-model="isShowingSolicitarBloqueioModal"
      :width="640"
      scroll="keep"
    >
      <SolicitarBloqueioModal
        :analise="analise"
        @bloqueio="bloqueio"
      ></SolicitarBloqueioModal>
    </b-modal>

    <b-loading
      :is-full-page="true"
      v-model="isLoading"
      :can-cancel="false"
    ></b-loading>
  </div>
</template>

<script>
import * as dayjs from 'dayjs'
import { mapState } from 'vuex'
import flash from '@/mixins/flash.js'
import TableReservasVue from './TableReservas.vue'
import SolicitarBloqueioModal from './SolicitarBloqueioModal.vue'
import services from '@/http'
import AnexoAnaliseModal from '@/components/AnexoAnaliseModal.vue';
import AnaliseAssociada from './AnaliseAssociada.vue'
import AnaliseChecklist from './AnaliseChecklist.vue'

export default {
  components: {
    TableReservasVue,
    SolicitarBloqueioModal,
    AnexoAnaliseModal, 
    AnaliseAssociada,
    AnaliseChecklist
  },
  mixins: [flash],
  data() {
    return {
      analise: {
        AnaliseSolicitacaoID: null,
        DataCriacao: null,
        filial: null,
        Telefone: null,
        Email: null,
        pessoa: {
          CPFCNPJ: null,
        },
        pessoaCPFCNPJ: null,
        analista: null,
        ObsSolicitacao: null,
        ObsConclusao: null,
        analise_conclusao: {
          Descricao: null,
          PessoasBloqueioTipoID: null,
        },
        analise_tipo: {
          Descricao: null,
        },
        analise_origem: {
          Descricao: null,
        },
        analise_status: {
          Descricao: null,
        },
        analise_motivo: {
          Descricao: null,
        },
        usuario: {
          Nome: null,
        },
        pessoa: {
          Url: null,
        },
      },
      parametros: null,
      statusConclusaoDisponibilizarSolicitarBloqueio: [1, 2],
      allowBloqueio: false,
      isLoading: true,
      isShowingSolicitarBloqueioModal: false,
      isComponentModalAnexoActive: false,
      formProps: {
        AnaliseSolicitacaoID: this.$route.params.id,
      },
      tipoBloqueioFilhas: null,

      // Recebe as análises associadas
      // a análise atual
      associadas: [],
      resource: {
        Imagem: null,
        ImagemUrl: null,
        NroDocumento: null,
        Resultado: null,
        DataInicioAnalise: null,
        DataFinalAnalise: null,
        Duracao: null,
        ProcessoID: null,
        status_onboarding: {
          Descricao: null,
        },
        filial: {
          Loja: null,
          CNPJ: null,
          Email: null,
          Fone: null,
        },
        empresa: {
          Descricao: null,
        },
        tipo_analise_onboarding: {
          Descricao: null,
        },
        usuario: {
          Nome: null,
          Email: null,
          Fone: null,
        },
      },
      isLoading: true,
      pessoa: {
        RazaoSocial: "",
        Endereco: "",
        Nro: "",
        Bairro: "",
        Cidade: "",
        Estado: "",
      },
    };
  },
  methods: {
    fetch() {
        services.analiseFraude.analiseFraudeId({id: this.$route.params.id}).then(res => {

            const body = res.body;

            if(!body.success) {
              this.flash("danger", "Não foi possível visualizar a solicitação.");
				      return;
            }

            this.analise = body.response;

            this.analise.pessoaCPFCNPJ = this.analise.pessoa.CPFCNPJ.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");

            console.log(this.analise);

            let hoje = new dayjs()
            let diferenca = hoje.diff(new dayjs(this.analise.DataConclusao), 'hour', true);

            // console.log('Timestamp de hoje: ' + hoje)
            // console.log('Diferença em timestamp: ' + diferenca)
            // console.log('Parâmetro de diferença configurado:', this.parametros['Analise:HorasBloqueioAposAnalise'])
            // console.log('Permite bloqueio por diferença de tempo?', diferenca <= this.parametros['Analise:HorasBloqueioAposAnalise'])
            // console.log('O status da análise pode executar bloqueio após análise?', this.statusConclusaoDisponibilizarSolicitarBloqueio.includes(this.analise.AnaliseConclusaoID))
            // console.log('Bloqueio após análise solicitado em:', this.analise.BloqueioAposAnaliseEm)

            if (this.statusConclusaoDisponibilizarSolicitarBloqueio.includes(this.analise.AnaliseConclusaoID) == true && diferenca <= this.parametros['Analise:HorasBloqueioAposAnalise'] && this.analise.BloqueioAposAnaliseEm == null) {
              this.allowBloqueio = true
            }

        }).catch(error => {
          console.error('Erro na Promise:', error);
        }).finally((data) => {
          this.$root.$emit('resource-loaded');
          this.isLoading = false
        });
    },

    fetchParametros() {
      this.$http.get('api/v1/parametro?fetch=array').then(function(res) {
        this.parametros = res.body.response
        this.fetch()
      }).catch(error => {
        console.error('Erro na Promise:', error);
      })
    }, 

    openSolicitarBloqueioModal() {
      this.isShowingSolicitarBloqueioModal = true;
    },

    // Chama a rotina de bloqueio
    // através do evento
    bloqueio(justificativa) {
      console.log("Realizando bloqueio após análise!");
      this.handleBloqueio(this.analise, justificativa);
    },

    // Realiza as rotinas relacionadas ao bloqueio
    // após análise.
    handleBloqueio(analise, justificativa, analisePai = null, tipoBloqueio = null) {

      console.log('Handling bloqueio', analise, analisePai, tipoBloqueio)
      
      this.isLoading = true

      this.$http.post('api/v1/analise/solicitacao/' + analise.AnaliseSolicitacaoID + '/bloqueio/apos/analise', {
        AnaliseSolicitacaoID: analise.AnaliseSolicitacaoID, 
        UsuarioID: this.user.id, 
        BloqueioTipoID: tipoBloqueio == null ? this.parametros['Analise:TipoBloqueioTemporario'] : tipoBloqueio, 
        FlgTemporario: this.parametros['BloqueioAposAnalise:EncerramentoAutomatico'] == 'S' ? 0 : 1, 
        ObsSolicitacao: justificativa
      }).then((res) => {
        if (res.body.success) {
          this.isShowingSolicitarBloqueioModal = false
          this.allowBloqueio = false
          this.flash('success', 'Solicitação de bloqueio após análise criada com sucesso!')
        } else {
          this.flash('danger', res.body.response[Object.keys(res.body.response)[0]])
        }
      }).catch(error => {
        console.error('Erro na Promise:', error);
      }).finally(() => {
        this.isLoading = false
      })

    },

    abrirAnexoAnaliseModal() {
      this.isComponentModalAnexoActive = true;
    },
  },
  mounted() {
    // Recebe as análises associadas
    // a análise principal
    this.$root.$on("analise-associada-loaded", (params) => {
      this.associadas = params.analises;
    });

    this.fetchParametros();
    this.fetch();
  },
  computed: {
    ...mapState({
      user(state) {
        return state.auth.user;
      },
    }),
  },
};
</script>
