import AnaliseSolicitacao from './pages/AnaliseSolicitacao'
import AnaliseSolicitacaoShow from './pages/AnaliseSolicitacaoShow';
import AnaliseSolicitacaoCreate from './pages/AnaliseSolicitacaoCreate';

export default [
    {
        path: '/analise/solicitacoes',
        name: 'analise.solicitacoes',
        query: {
            solicitacao: null
        }, 
        component: AnaliseSolicitacao,
        meta: {
            permission: 'analise.solicitacoes'
        }
    },
    {
        path: '/analise/solicitacoes/create',
        name: 'analise.solicitacoes.create',
        component: AnaliseSolicitacaoCreate,
        meta: {
            permission: 'analise.solicitacoes'
        }
    },
    {
        path: '/analise/solicitacoes/:id',
        name: 'analise.solicitacoes.show',
        component: AnaliseSolicitacaoShow,
        meta: {
            permission: 'analise.solicitacoes'
        }
    }, 
]