<template>
  <card title="Análises associadas" subtitle="Análises que estão associadas a análise atual">

    <div v-if="!isLoading">
      
      <div class="is-flex is-justify-content-space-between">
        <b-taglist v-if="data.length > 0">
          <b-tag type="is-primary">{{ aprovadas.length }} aprovada(s)</b-tag>
        </b-taglist>
        <div>
          <b-button @click="fetch" type="is-small is-primary is-light"><i class="fa-solid fa-arrows-rotate fa-fw me-1"></i> Atualizar</b-button>
        </div>
      </div>      

      <div v-if="data.length > 0">
        <b-table :data="data" :striped="true" :hoverable="true">
          <b-table-column label="#" v-slot="props">
            <router-link tag="a" :to="link + props.row.AnaliseSolicitacaoID" target="_blank">{{
                props.row.AnaliseSolicitacaoID
            }}</router-link>
          </b-table-column>

          <b-table-column label="Cliente" v-slot="props">
            <span v-if="props.row.pessoa">{{ props.row.pessoa.Pessoa }}</span>
          </b-table-column>

          <b-table-column label="CPF" v-slot="props">
            <span v-if="props.row.pessoa">{{ props.row.pessoa.CPFCNPJ }}</span>
          </b-table-column>

          <b-table-column label="Tipo" v-slot="props">
            {{ props.row.PessoaTipo }}
          </b-table-column>

          <b-table-column label="Origem" v-slot="props">
            <span v-if="props.row.analise_origem">{{
                props.row.analise_origem.Descricao
            }}</span>
          </b-table-column>

          <b-table-column label="Conclusão" v-slot="props">
            <strong v-if="props.row.analise_conclusao">{{
                props.row.analise_conclusao.Descricao
            }}</strong>
            <span v-else>
              -
            </span>
          </b-table-column>

        </b-table>
      </div>
      <div v-else class="mt-1 notification is-warning is-light">
        <i class="fa-solid fa-circle-info fa-fw mr-1"></i> Nenhuma análise pai associada a esta análise.
      </div>
    </div>

    <div v-else>
      <b-progress type="is-primary" size="is-small"></b-progress>
    </div>
  </card>
</template>

<script>
export default {

  props: {
    pai: {
      type: Number,
      required: true,
    },
    link: {
      type: String,
      default: "/dashboard/analiseFraude/",
    },
  },

  data() {
    return {
      isLoading: true,
      data: [],
    };
  },

  methods: {
    fetch() {
      this.$http
        .get("api/v1/analise/solicitacao?fetch=get&pai=" + this.pai)
        .then(function (res) {
          this.data = res.data.response;
          this.$root.$emit("analise-associada-loaded", { analises: this.data, aprovadas: this.aprovadas });
        }).catch(error => {
          console.error('Erro na Promise:', error);
        })
        .finally(function () {
          this.isLoading = false;
        });
    },
  },

  mounted() {
    this.$root.$on("resource-loaded", () => {
      this.fetch();
    });
  },
  computed: {
    aprovadas() {

      return this.data.filter((item) => {
        if (item.analise_conclusao) {
          return item.analise_conclusao.AnaliseConclusaoID == 1 || item.analise_conclusao.AnaliseConclusaoID == 2;
        }
      });
    },
  },
};
</script>
