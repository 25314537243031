export default {
    get: {
        method: 'get', 
        url: 'api/v1/operacao/eventoStatus'
    }, 
    store: {
        method: 'post', 
        url: 'api/v1/operacao/eventoStatus'
    }, 
    update: {
        method: 'post', 
        url: 'api/v1/operacao/eventoStatus/update'
    }, 
    show: {
        method: 'get', 
        url: 'api/v1/operacao/tipo{/tipo}'
    }
}