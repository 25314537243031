<template>
  <div class="home">
    <navbar></navbar>
    <div class="columns">
      <div class="movida-sidebar aside">
        <nav-manage></nav-manage>
      </div>
      <div class="column main container">
        <div class="titutlo pt-5 pb-3 mb-5">
          <div class="is-flex is-flex-direction-row is-align-items-center is-justify-content-space-between	">
            <div class="is-flex is-flex-direction-row is-align-items-center">
              <b-icon
                  icon="home"
                  size="is-medium">
              </b-icon>
              <h2 class="ml-3 is-size-3	has-text-weight-semibold">Home</h2>

          </div>

        </div>

      </div>
      
      </div>
      
    </div>
  </div>
</template>

<style scoped>
  .movida-sidebar{
    width: 260px;
  }

  .main {
  
    padding: 20px;
  }

  .titutlo{
    border-bottom: 1px solid #D4D4D4 ;
  }

</style>

<script>
import SidebarComponent from '@/components/SidebarComponent.vue'; // @ is an alias to /src
import NavbarComponent from '@/components/NavbarComponent.vue'; // @ is an alias to /src

import { mapState } from 'vuex'

export default {
  components: {
    "nav-manage":SidebarComponent,
    "navbar": NavbarComponent,
  },
  computed: {
    ...mapState({
      user(state) {
          return state.auth.user;
      }
    })
  }, 
  mounted() {
    
    // Chama o endpoint responsável por
    // sincronizar o usuário do Vetor
    // na base de dados do PRF
    this.$http.post('api/v1/perfil/sync', this.user).then(response => {
      console.log(response);
    }).catch(error => {
      console.log(error);
    });

  }
}


</script>
