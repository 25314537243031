<template>
  <layout>
    <page-title subtitle="Listando análises disponíveis">
        Análise de Solicitações

        <div slot="actions">
          <b-button @click="novaSolicitacao" type="is-primary is-light"><i class="fa-solid fa-plus fa-fw mr-1"></i> Nova solicitação</b-button>
        </div>
    </page-title>
    <hr>
    <Table/>
  </layout>
</template>

<script>

import Table from '../components/Table.vue';

export default {
  components: {
    Table
  }, 

  methods: {
    novaSolicitacao() {
      this.$router.push({ name: 'analise.solicitacoes.create', query: { filtros: true } }).catch(()=>{});
    }
  }
}


</script>
