<template>
    <div class="box">
        <h4 class="title is-6">Nova operação</h4>
        <b-field
            :type="value.Descricao == '' ? 'is-danger' : ''"
            :message="value.Descricao == '' ? 'Obrigatório' : ''"
            label="Descrição">
            <b-input required v-model="value.Descricao" placeholder="Informe uma descrição..."></b-input>
        </b-field>
        <b-field 
            label="Ativo"
            :type="value.Ativo == '' ? 'is-danger' : ''"
            :message="value.Ativo == '' ? 'Obrigatório' : ''">
            
            <b-select required v-model="value.Ativo" expanded>
                <option value="">Por favor, selecione...</option>
                <option value="S">Sim</option>
                <option value="N">Não</option>
            </b-select>
        </b-field>

        <hr>
        <b-button type="is-primary pulled-right" @click="save"><i class="fa-solid fa-floppy-disk fa-fw mr-1"></i> Salvar</b-button>
    </div>
</template>

<script>

export default {

    props: ['tipo'],
    data(){
        return {
            value: {
                Descricao: this.tipo.Descricao,
                Ativo: this.tipo.Ativo
            }
        }

    },
    methods: {
        save(){
            this.tipo.Descricao = this.value.Descricao;
            this.tipo.Ativo = this.value.Ativo;
            this.$emit('save')
        }
    }

}

</script>