import { routes as credDefenseRoutes } from './creddefense';
import { routes as analiseFraudeRoutes } from './analiseFraude';
import { routes as notificacaoMovimentacao } from './notificacaoMovimentacao';
import { routes as homeRoutes } from './home';

export default [
    ...credDefenseRoutes,
    ...analiseFraudeRoutes,
    ...homeRoutes,
    ...notificacaoMovimentacao,
];