import AcompanhamentoValidacaoBiometricaIndex from './pages/AcompanhamentoValidacaoBiometricaIndex'
import AcompanhamentoValidacaoBiometricaShow from './pages/AcompanhamentoValidacaoBiometricaShow'

export default [
    {
        path: '/acompanhamento/validacao/biometrica',
        name: 'acompanhamento.validacao.biometrica',
        component: AcompanhamentoValidacaoBiometricaIndex,
        meta: {
            permission: 'acompanhamento.validacao.biometrica'
        }
    },
    {
        path: '/acompanhamento/validacao/biometrica/:id',
        name: 'acompanhamento.validacao.biometrica.show',
        component: AcompanhamentoValidacaoBiometricaShow,
        meta: {
            // TOOD: Ajustar permissão
            permission: 'acompanhamento.validacao.biometrica'
        }
    },

]