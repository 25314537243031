import store from '../store'
import * as storage from '../modules/auth/storage';
import router from '.';
import { permissions } from '@/modules/auth/store/getters';
import { mapActions } from 'vuex';


export default async (to, from, next) => {

  document.title = `PRF - Recuperação e Fraude`

  // Verifica se a rota é SSO 
  // (Single Sign On). Caso seja, 
  // então realizamos a autenticação do usuário.
  if (to.name === 'sso' || to.name == 'midia.hash') {
    next()
  } else {
    if (to.name !== 'login' && !store.getters['auth/hasToken']) {

      try {
        await store.dispatch('auth/ActionCheckToken')
        router.push({ path: to.path })
      } catch (err) {
        next({path:'/'})
      }
    } else {
      if (to.name === 'login' && store.getters['auth/hasToken']) {
        next({ name: 'home' })
      } else {
        if(Object.keys(to.meta).length != 0){
          let permission = storage.getPermission();
        
          let p = await JSON.parse(permission);
          let can_navigate = false;

          if (p == null) {
                  
            alert('Atenção: O usuário não possui permissão para acessar o sistema PRF. Redirecionando para o login 2')
            store.dispatch('auth/ActionSignOut')  
            next();
            next({path:'/'})
            return false;
          }

          p.forEach(element => {
            if(element == to.meta.permission){
              can_navigate = true;
            }
          });
          if(can_navigate){
            next();
          }else{
            if (permissions.length == 0) {
              alert('Atenção: O usuário não possui permissão para acessar o sistema PRF. Redirecionando para o login')
              store.dispatch('auth/ActionSignOut')
              next({path:'/'})
              return false;
            }
            next({path:'/'})
          }
        }else{
          next();
        }
      }
    }
  }
}