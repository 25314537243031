<template>
    <div class="is-block is-flex-tablet is-justify-content-space-between is-align-content-center">
        <div>
            <h3 class="title"><slot></slot></h3>
            <h2 v-if="subtitle" class="subtitle">{{ subtitle }}</h2>
        </div>

        <slot name="actions"></slot>
    </div>
</template>

<script>

export default {

    props: {
        subtitle: {
            type: String, 
            required: false
        }
    }
    
}

</script>