import IndexView from './pages/IndexView';

export default [
    {
        path: '/dashboard/home',
        name: 'dashboard_home',
        component: IndexView,
        meta: {
            permission: 'dashboard.home'
        }
    },
]