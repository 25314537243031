import IndexView from './pages/IndexView';

export default [
    {
        path: '/fraudes/parametros_filial/:id',
        name: 'parametros.fraudes',
        component: IndexView,
        meta: {
            permission: 'parametros.fraudes'
        }
    },
];