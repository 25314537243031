import Vue from 'vue'
  Vue.directive('can', {
    bind: function (el, binding, vnode) {
      if (! JSON.parse(localStorage.getItem('permissions'))
          .includes(binding.expression
            .replace(/'/g, "")
              .replace(/"/g, ""))) {
         vnode.elm.style.display = "none";
      }
    }
  })

  Vue.directive('cans', {
    bind: function (el, binding, vnode) {
      let expressionArray = binding.expression.split("|");
      let display = false;
      expressionArray.forEach(element => {
        if (JSON.parse(localStorage.getItem('permissions'))
        .includes(element
          .replace(/'/g, "")
            .replace(/"/g, ""))) {
          display = true;
        }
      });

      if(!display)
        vnode.elm.style.display = "none";
    }
  })