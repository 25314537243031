import AnaliseMotivoIndex from "./pages/AnaliseMotivoIndex";

export default [
  {
    path: "/analise/motivo",
    name: "analise.motivo",
    component: AnaliseMotivoIndex, 
    meta: {
      permission: 'cadastros.analise.motivo'
    }
  }
];
