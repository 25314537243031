<template>
  <layout>
    <page-title subtitle="Listando análises encontradas">
      Acompanhamento das validações biométricas
    </page-title>
    <hr />

    <card title="Filtros">
      <b-field
        grouped
        group-multiline
        class="is-mobile has-text-left is-flex is-justify-content-center"
      >
        <b-field
          class="column is-4 is-half-tablet pr-0 pl-0 is-align-self-flex-end"
          label="Número da Análise"
        >
          <b-input
            placeholder="Digite o número da análise"
            type="search"
            v-model="filters.analise"
            v-mask="'########'"
          >
          </b-input>
        </b-field>
        <b-field
          class="column is-4 is-half-tablet pr-0 pl-0 is-align-self-flex-end"
          label="Documento"
        >
          <b-input
            placeholder="Digite o número do documento"
            type="search"
            v-model="filters.documento"
          >
          </b-input>
        </b-field>
        <b-field
          class="column half-tablet pr-0 pl-0 is-align-self-flex-end"
          label="Nome"
        >
          <b-input
            placeholder="Digite o nome"
            type="search"
            v-model="filters.nome"
          >
          </b-input>
        </b-field>
        <b-field
          class="column is-4 is-half-tablet pr-0 pl-0 is-align-self-flex-end"
          label=" Filial"
        >
          <b-select
            v-model="filters.filial"
            placeholder="Selecione uma filial"
            expanded
          >
            <option value="">Selecione uma filial</option>
            <option
              v-for="filial in filiais"
              v-bind:key="filial.FilialID"
              :value="filial.FilialID"
            >
              {{ filial.Loja }}
            </option>
          </b-select>
        </b-field>
        <b-field
          class="column is-4 is-half-tablet pr-0 pl-0 is-align-self-flex-end"
          label="Elegível"
        >
          <div class="control">
            <div class="select">
              <b-select
                v-model="filters.nao_elegivel"
                placeholder="Selecione uma opção"
                expanded
              >
                <option value="">Selecione uma opção</option>
                <option value="1">Não</option>
                <option value="2">Sim</option>
              </b-select>
            </div>
          </div>
        </b-field>
      </b-field>
      <b-field
        grouped
        group-multiline
        class="is-mobile has-text-left is-flex is-justify-content-center"
      >
        <b-field
          class="column is-4 is-half-tablet pr-0 pl-0 is-align-self-flex-end"
          label="A partir de"
        >
          <b-datepicker
            placeholder=""
            icon="calendar"
            trap-focus
            editable
            :icon-right="'close-circle'"
            @icon-right-click="clearDate('inicio')"
            icon-right-clickable
            v-model="filters.inicio"
          >
          </b-datepicker>
        </b-field>
        <b-field
          class="column is-4 is-half-tablet pr-0 pl-0 is-align-self-flex-end"
          label="Até"
        >
          <b-datepicker
            locale="pt-BR"
            placeholder=""
            icon="calendar"
            trap-focus
            editable
            :icon-right="'close-circle'"
            @icon-right-click="clearDate('termino')"
            icon-right-clickable
            v-model="filters.termino"
          >
          </b-datepicker>
        </b-field>
        <b-field class="is-1 pr-0 pl-0 column is-align-self-flex-end">
          <b-button @click="fetch(1)" type="is-primary" outlined
            >Buscar</b-button
          >
          <b-button @click="clear" type="ml-2 is-primary" outlined
            >Limpar</b-button
          >
        </b-field>
      </b-field>
    </card>

    <card title="Registros">
      <b-table
        :data="resources.data"
        :striped="true"
        :hoverable="true"
        :total="resources.total"
        :current-page="resources.current_page"
        :loading="isLoading"
        paginated
        backend-pagination
        @page-change="onPageChange"
      >
        <b-table-column label="#" v-slot="props">
          <router-link
            :to="
              '/acompanhamento/validacao/biometrica/' +
              props.row.AnaliseOnboardingID
            "
            ><strong>{{ props.row.AnaliseOnboardingID }}</strong></router-link
          >
        </b-table-column>
        <b-table-column label="Data" v-slot="props">
          {{
            new Date(props.row.DataCadastro) | dateFormat("DD/MM/YYYY HH:mm")
          }}
        </b-table-column>
        <b-table-column label="Filial" v-slot="props">
          <strong>{{ props.row.filial.Loja }}</strong>
        </b-table-column>
        <b-table-column label="Documento" v-slot="props">
          {{
            props.row.NroDocumento
              ? props.row.NroDocumento.replace(
                  /(\d{3})(\d{3})(\d{3})(\d{2})/,
                  "$1.$2.$3-$4"
                )
              : "(Não informado)"
          }}
        </b-table-column>
        <b-table-column label="Pessoa" v-slot="props">
          <router-link
            :to="
              '/acompanhamento/validacao/biometrica/' +
              props.row.AnaliseOnboardingID
            "
          >
            <strong v-if="props.row.pessoa != null">{{
              props.row.pessoa.RazaoSocial
            }}</strong>
            <strong v-else>-</strong>
          </router-link>
        </b-table-column>
        <b-table-column label="Elegível" v-slot="props">
          <strong>{{ props.row.NaoElegivel | booleanLabel }}</strong>
        </b-table-column>
        <b-table-column label="Resultado" v-slot="props">
            <span v-if="props.row.FlgAprovado == 0">Validação Negativa</span>
            <span v-if="props.row.FlgAprovado == 1">Validação Positiva</span>
            <span v-if="props.row.FlgAprovado == 2">Não submetido a validação</span>
        </b-table-column>
        <b-table-column label="Score" v-if="podeVerScore" v-slot="props">
          {{ props.row.Resultado ? props.row.Resultado : "(Não informado)" }}
        </b-table-column>
      </b-table>
    </card>
  </layout>
</template>

<script>
export default {
  data() {
    return {
      resources: {},
      podeVerScore: false, 

      filters: {
        analise: "",
        documento: "",
        nome: "",
        filial: "",
        nao_elegivel: "",
        inicio: null,
        termino: null,
      },

      filiais: [],

      isLoading: true,
    };
  },

  methods: {

    loadPage() {
      this.isLoading = true;

      this.$http.get('api/v1/perfil/privilegio?privilegio=1761').then(response => {
          let permissoes = response.data.response
          if (permissoes.length > 0) {
            this.podeVerScore = permissoes.some(permissao => permissao.PerfilID === this.$store.state.auth.user.perfilId)
          }
          this.fetch()
      })
    }, 

    fetch(page = 1) {
      this.isLoading = true;

      this.saveFilters();

      // Consulta
      this.$http
        .get("api/v1/analise/onboarding", {
          params: {
            with: "pessoa,filial,usuario,statusOnboarding,tipoAnaliseOnboarding,empresa",
            orderBy: "AnaliseOnboardingID,desc",
            page: page,
            inicio_em: this.filters.inicio
              ? new Date(this.filters.inicio).toISOString()
              : null,
            termino_em: this.filters.termino
              ? new Date(this.filters.termino).toISOString()
              : null,
            ...this.filters,
          },
        })
        .then((res) => {
          if (res.body.success) {
            this.resources = res.body.response;
            this.isLoading = false;
          }
        });
    },
    onPageChange(page) {
      this.fetch(page);
    },
    clearDate(field) {
      if (field == "inicio") {
        this.filters.inicio = null;
      }

      if (field == "termino") {
        this.filters.termino = null;
      }

      this.saveFilters();
    },
    clear() {
      this.filters = {
        analise: "",
        documento: "",
        nome: "",
        filial: "",
        nao_elegivel: "",
        inicio: null,
        termino: null,
      };

      this.saveFilters();
    },
    fetchFilial() {
      this.$http
        .get("api/v1/filial?fetch=get&orderBy=Loja,asc&usuario=")
        .then(({ data }) => {
          this.filiais = data.response;
        });
    },
    loadFilters() {
      let jsonFilters = JSON.parse(
        localStorage.getItem("acompanhamento.validacao.biometrica.filters")
      );

      const filtros = this.$route.query.filtros;

      if (filtros) {
        if (jsonFilters != undefined) {
          if (jsonFilters.inicio != undefined) {
            jsonFilters.inicio = new Date(jsonFilters.inicio);
          }

          if (jsonFilters.termino != undefined) {
            jsonFilters.termino = new Date(jsonFilters.termino);
          }
          this.filters = jsonFilters;
        }
      } else {
        localStorage.removeItem("acompanhamento.validacao.biometrica.filters");
      }
    },
    saveFilters() {
      localStorage.setItem(
        "acompanhamento.validacao.biometrica.filters",
        JSON.stringify(this.filters)
      );
    },
  },

  created() {
    this.loadFilters();
  },

  mounted() {
    this.loadPage();
    this.fetch();
    this.fetchFilial();
  },

  filters: {
    booleanLabel(value) {
      if (value === 0) {
        return "Sim";
      } else if (value === 1) {
        return "Não";
      }
      return value;
    },
  },
};
</script>
