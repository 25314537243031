<template>
    <layout>
        <page-title subtitle="Listando os prestadores disponíveis">
            Prestadores

            <div slot="actions">
                <b-button type="is-primary is-light" @click="isFormModalActive = ! isFormModalActive"><i class="fa-solid fa-plus fa-fw mr-1"></i>Novo Prestador</b-button>
            </div>
        </page-title>

        <!-- Registros-->
        <div class="box my-5">
            <b-table 
                :data="data" 
                :striped="true" 
                :hoverable="true" >
                
                <b-table-column label="Descrição" v-slot="props">
                    <a  @click.prevent="edit(props.index)"><strong>{{ props.row.Descricao }}</strong></a>
                </b-table-column>

                <b-table-column label="E-mail" v-slot="props">
                    {{ props.row.Email }}
                </b-table-column>

                <b-table-column label="Ativo?" centered v-slot="props">
                    <span v-if="props.row.Ativo == 'S'" class="tag is-success">Ativo</span>
                    <span v-else class="tag is-danger">Inativo</span>
                </b-table-column>

                <b-table-column label="#" width="40" numeric v-slot="props">
                    {{ props.row.PrestadorID }}
                </b-table-column>

                <b-table-column label="Ações" centered v-slot="props">
                    <b-tooltip label="Editar" type="is-dark">
                        <a href="#" @click.prevent="edit(props.index)"><i class="fa-solid fa-pen-to-square fa-fw"></i></a>
                    </b-tooltip>
                </b-table-column>
            </b-table>
        </div>

        <!-- Modal com formulário de cadastro -->
        <b-modal @close="clear" v-model="isFormModalActive" :width="640" scroll="keep">
            <tipo-form @save="save" :tipo="tipo"></tipo-form>
        </b-modal>
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>

    </layout>
</template>


<script>
    import services from '@/http';
    import flash from '@/mixins/flash.js';
    import TipoForm from '@/modules/operacao/tipo/components/TipoFormPrestador.vue';

    export default {
        components: {
            TipoForm
        },
        mixins: [flash], 
        data() {
            return {
                tipo: null, 
                data: [], 
                isFormModalActive: false, 
                isLoading: false
            }
        }, 
        methods: {
            fetch() {
                this.isLoading = true;
                services.operacaoPrestador.get().then(res => this.data = res.body.response)
                    .finally(() => {
                        this.isLoading = false;
                    });
            }, 

            edit(index) {
                this.isFormModalActive = true;
                this.tipo = this.data[index];
            }, 

            save() {
                this.tipo.PrestadorID === undefined ? this.store() : this.update(this.tipo)
            }, 

            store() {
                if(this.tipo.Descricao == ''|| this.tipo.Email == '' || this.tipo.Ativo == '' || typeof this.tipo.Ativo == 'undefined'){
                
                    this.flash('danger', "Todos os campos obrigatórios precisam ser preenchidos.")
                    return;
                }

                
                if(!this.validateEmail(this.tipo.Email)){
                    this.flash('danger', "Digite um e-mail válido.")
                    return;
                }


                this.isLoading = true;


                services.operacaoPrestador.store(this.tipo).then(res => {
                    if (res.body.success) {

                        this.flash('success', res.body.response.msg)

                        this.isFormModalActive = false;
                        this.clear()
                        this.fetch()
                    } else {
                        this.flash('danger', res.body.response.msg)
                    }
                }).finally(() => {
                    this.isLoading = false;
                })
            }, 

            update(tipo) {
                if(this.tipo.Descricao == '' || this.tipo.Email == '' || this.tipo.Ativo == '' || typeof this.tipo.Ativo == 'undefined'){
                    this.flash('danger', "Todos os campos obrigatórios precisam ser preenchidos.")
                    this.clear()
                    this.fetch()
                    return;
                }

                if(!this.validateEmail(this.tipo.Email)){
                    this.flash('danger', "Digite um e-mail válido.")
                    return;
                }

                this.isLoading = true;


                services.operacaoPrestador.update({ tipo: tipo.EventoStatusID }, tipo).then(res => {
                    if (res.body.success) {

                        this.flash('success', res.body.response.msg)

                        this.isFormModalActive = false;
                        this.clear()
                        this.fetch()
                    } else {
                        this.flash('danger', res.body.response.msg)
                    }
                }).finally(() => {
                    this.isLoading = false;
                })
            }, 

            clear() {
                this.tipo = {
                    Descricao: '', 
                    Ativo: '', 
                    Email: ''
                }
            },
            validateEmail(email){
                return email.match(
                    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
            }
        }, 
        mounted() {
            this.fetch()
            this.clear()

            this.$root.$on('save-form', () => {
                this.save()
            })
        }
    }

</script>
