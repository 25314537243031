<template>
  <layout>
    <page-title subtitle="Listando análises disponíveis">
        Painel de Solicitações
    </page-title>
    <hr>
    <Table/>
  </layout>
</template>

<script>
import Table from '../components/Table.vue';
import SidebarComponent from '@/components/SidebarComponent.vue'; // @ is an alias to /src
import NavbarComponent from '@/components/NavbarComponent.vue'; // @ is an alias to /src


export default {
  components: {
    Table,
    "nav-manage":SidebarComponent,
    "navbar": NavbarComponent,
  }
}


</script>
