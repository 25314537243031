<template>
    <layout>

        <b-loading :is-full-page="true" v-model="isLoading"></b-loading>

        <page-title subtitle="Listando as configurações e parâmetros disponíveis para o módulo">
            Parâmetros
        </page-title>

        <!-- Registros-->
        <card title="Parâmetros">
            <div class="is-block mb-4" v-for="(parametro, index) in parametros" v-bind:key="index">
                <div v-if="parametro.FlgGlobal == 'S'" class="columns">
                    <div class="column">
                        <b-field :label="parametro.Descricao">
                            
                            <!-- String -->
                            <b-input v-if="parametro.RegraValidacao.type == 'string'" v-model="parametros[index].Conteudo"></b-input>

                            <!-- Integer -->
                            <b-input type="number" v-if="parametro.RegraValidacao.type == 'integer'" v-model="parametros[index].Conteudo"></b-input>
                            
                            <!-- Select -->
                            <b-select v-if="parametro.RegraValidacao.type == 'select'" v-model="parametros[index].Conteudo" placeholder="Selecione...">
                                <option v-for="(option, optionIndex) in parametro.RegraValidacao.data" v-bind:key="optionIndex" :value="option.value">
                                    {{ option.label }}
                                </option>
                            </b-select>

                            <!-- Select com tabelas -->
                            <b-select v-if="parametro.RegraValidacao.type == 'table'" :loading="parametro.Loading" v-model="parametros[index].Conteudo" placeholder="Selecione...">
                                <option v-for="(option, optionIndex) in parametro.RegraValidacao.data.options" v-bind:key="optionIndex" :value="option.value">
                                    {{ option.label }}
                                </option>
                            </b-select>
                            
                            <!-- Date -->
                            <b-datepicker 
                                v-if="parametro.RegraValidacao.type == 'date'" 
                                v-model="parametros[index].Conteudo" 
                                icon="calendar"
                            ></b-datepicker>
                        </b-field>
                    </div>
                </div>
            </div>
        </card>

        <div class="card">
            <div class="card-content">
                <b-button @click="update" class="is-primary is-large"><i class="fa-regular fa-floppy-disk fa-fw mr-1"></i> Salvar configurações</b-button>
            </div>
        </div>

    </layout>
</template>


<script>

    import flash from '@/mixins/flash.js';

    export default {

        mixins: [flash], 

        data() {
            return {
                isLoading: true, 
                parametros: {}
            }
        }, 

        methods: {
            fetch() {
                this.isLoading = true
                this.$http.get('api/v1/parametro/tela?orderBy=Descricao,asc&modulo=' + this.$route.params.id).then((res) => {
                    this.parametros = res.body.response.map((item) => {
                        item.Loading = false
                        item.RegraValidacao = JSON.parse(item.RegraValidacao)

                        if (item.RegraValidacao.type == 'date') {
                            item.Conteudo = new Date(item.Conteudo + 'T00:00:00')
                        }

                        if (item.RegraValidacao.type == 'table') {
                            item.Loading = true
                            this.$http.get(item.RegraValidacao.data.endpoint).then((res) => {

                                if(res.body.response && typeof res.body.response.map === 'function'){
                                    item.RegraValidacao.data.options = res.body.response.map((responseItem) => {
                                    responseItem.label = responseItem[item.RegraValidacao.data.label]
                                    responseItem.value = responseItem[item.RegraValidacao.data.value]
                                    return responseItem
                                })
                                }

                                

                                item.Loading = false
                            })
                        }

                        return item
                    })

                    this.isLoading = false
                })
            }, 
            update() {
                this.isLoading = true

                let form = this.parametros

                form.map((item) => {
                    if (item.RegraValidacao.type == 'date') {
                        item.Conteudo = item.Conteudo.toISOString().split('T')[0]
                    }
                })

                this.$http.post('api/v1/parametro/tela', { parametros: form }).then((res) => {
                    this.flash('success', res.body.response.msg)
                    this.fetch()
                })
            }
        }, 

        mounted() {
            this.fetch()
        }, 

        watch: {
            '$route.params.id': {
                handler () {
                    this.fetch()
                }
            }
        },
    }

</script>
