<template>
    <div class="pessoaview">
        <div class="columns  is-mobile is-desktop">
            <div class="column">
                <b-image
                :src="fotoUrl"
                src-fallback=""
                ></b-image>
            </div>
            <div class="column">
                    <b-field label="Data">
                        <b-input readonly v-model="data"></b-input>
                    </b-field>
                    <b-field label="CPF">
                        <b-input readonly v-mask="'###.###.###-##'" v-model="cpf"></b-input>
                    </b-field>
                    <b-field label="Data de Nascimento">
                        <b-input readonly v-model="nascimento"></b-input>
                    </b-field>
            </div>
            <div class="column">
                        <b-field label="Filial">
                            <b-input readonly v-model="filial"></b-input>
                        </b-field>
                        <b-field label="Nome">
                            <b-input readonly v-model="nome"></b-input>
                        </b-field>
                        <b-field label="Resultado Biometria">
                            <b-input readonly v-model="biometria"></b-input>
                        </b-field>
            </div>
        </div>
    </div>
    
</template>

<style scoped>
  .movida-sidebar{
    width: 260px;
  }

  .main {
  
    padding: 20px;
  }

  .titutlo{
    border-bottom: 1px solid #D4D4D4 ;
  }

</style>

<script>
import services from '@/http';

import * as dayjs from 'dayjs'



export default {
    data() {
        return {
            data: '',
            cpf: '',
            nascimento: '',
            filial: '',
            nome: '',
            biometria: '',
            fotoUrl: '',
            id: this.$route.params.id,
        }
    },
    methods: {
        carregarHistorico(){
            //this.$http.get(`http://localhost:8082/api/credDefense/1`).then(res => {
            console.log(this.id);
            services.creddefense.pessoa({id: this.id})
                .then(res => {
                const { CPF, FotoUrl,  DataCadastro, DataNasc, NomeFilial, ResultadoBiometria, Nome } = res.body;
                this.cpf = CPF;
                this.filial = NomeFilial;
                this.nome = Nome;
                this.biometria = ResultadoBiometria;
                this.nascimento = new dayjs(DataNasc).format('DD/MM/YYYY')
                this.data = new dayjs(DataCadastro).format('DD/MM/YYYY HH:mm:ss'),
                this.fotoUrl = FotoUrl
            })
        }
    },
    mounted() {
        this.carregarHistorico();
    }
}
    



</script>
