import IndexView from './pages/IndexView';
import ShowView from './pages/ShowView';

export default [
    {
        path: '/operacao/tipo',
        name: 'operacao.tipo',
        component: IndexView,
        meta: {
            permission: 'operacao.tipo', 
            keepAlive: false
        }
    }, 
    {
        path: '/operacao/tipo/:id',
        name: 'operacao.tipo.show',
        component: ShowView,
        meta: {
            permission: 'operacao.tipo', 
            keepAlive: false
        }
    }
];