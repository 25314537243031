import { setBearerToken } from '@/http';

export const setHeaderToken = token => setBearerToken(token);
export const getLocalToken = () => localStorage.getItem('token');
export const deleteLocalToken = () => localStorage.removeItem('token');
export const setLocalToken = token => localStorage.setItem('token', token);
export const setPermissions = token => localStorage.setItem('permissions', token);
export const getPermission = () => localStorage.getItem('permissions');

export const deleteCookieToken = () => {
    $cookies.remove("token_user");
    $cookies.remove("token_app");
    $cookies.remove("token")
}

export const deleteCookieDomainToken = () => {
    const domain = process.env.VUE_APP_DOMAIN_COOKIES;
    $cookies.remove("token_user", "/", domain);
    $cookies.remove("token_app", "/", domain);
    $cookies.remove("token", "/", domain);
}