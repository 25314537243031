import * as types from './mutation-types';

export default {
    [types.SET_USER] (state, paylod){
        state.user = paylod
    },
    [types.SET_TOKEN] (state, payload){
        state.token = payload
    },
    [types.SET_PERMISSIONS] (state, payload){
        state.permissions = payload
    }
}