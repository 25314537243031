import AnaliseTipoIndex from "./pages/AnaliseTipoIndex";

export default [
  {
    path: "/analise/tipo",
    name: "analise.tipo",
    component: AnaliseTipoIndex, 
    meta: {
      permission: 'cadastros.analise.tipo'
    }
  }
];
