import AnaliseOrigemIndex from "./pages/AnaliseOrigemIndex";
import AnaliseOrigemShow from "./pages/AnaliseOrigemShow";

export default [
  {
    path: "/analise/origem",
    name: "analise.origem",
    component: AnaliseOrigemIndex, 
    meta: {
      permission: 'cadastros.analise.origem'
    }, 
  }, 
  {
    path: "/analise/origem/:id",
    name: "analise.origem.show",
    component: AnaliseOrigemShow, 
    meta: {
      permission: 'cadastros.analise.origem'
    }, 
  }
];
