export default {
    listar: { method: 'get', url: `api/v1/dashboard/analiseFraudes?cpf={cpf}&nome={nome}&dataInicio={dataInicio}&dataFim={dataFim}&perPage={perPage}&page={page}&regional={regional}&solicitacao={solicitacao}` },
    analiseFraudeId: { method: 'get', url: 'api/v1/dashboard/analiseFraudes{/id}' },

    concluirAnalise: { method: 'post', url: 'api/v1/dashboard/concluirAnalise' },
    atribuirAnalise: { method: 'post', url: 'api/v1/dashboard/atribuirAnalise' },

    analiseConclusao: { method: 'get', url: 'api/v1/dashboard/analiseConclusao' },
    analiseMotivo: { method: 'get', url: 'api/v1/dashboard/analiseMotivo' },
    analiseOrigem: { method: 'get', url: 'api/v1/dashboard/analiseOrigem' },
    analiseStatus: { method: 'get', url: 'api/v1/dashboard/analiseStatus' },
    analiseTipo: { method: 'get', url: 'api/v1/dashboard/analiseTipo' },
    pessoasBloqueioTipo: { method: 'get', url: 'api/v1/dashboard/pessoasBloqueioTipo' },
    reservas: { method: 'get', url: 'api/v1/analise{/id}/reservas'},

    solicitacaoAnaliseAdicionarAnexo: { method: 'post', url: 'api/v1/analiseSolicitacaoAnexo/adicionar'},
    solicitacaoAnaliseBuscarAnexos: { method: 'get', url: 'api/v1/analiseSolicitacaoAnexo/buscar/{AnaliseSolicitacaoID}'},
    solicitacaoAnaliseDeletarAnexo: { method: 'delete', url: 'api/v1/analiseSolicitacaoAnexo/deletar'},
    solicitacaoAnaliseGerarUrlTemporaria: { method: 'get', url: 'api/v1/analiseSolicitacaoAnexo/gerarUrlTemporaria/?AnaliseSolicitacaoID={AnaliseSolicitacaoID}'},

    analiseTipoAnexoBuscar: { method: 'get', url: 'api/v1/analiseTipoAnexo/buscar'}

}