<template>
    <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
        <p class="modal-card-title">Dados</p>
        <button
            type="button"
            class="delete"
            @click="$emit('close')"/>
    </header>
        <section class="modal-card-body">
            <div class="pessoaview">
                <div class="rows is-mobile is-desktop">
                    <section>
                        <div v-if="formProps.DataConclusao == null && userId === formProps.AnaliseUsuarioID" class="columns is-mobile">
                            <div class="column">
                                <b-field label="Tipo de Anexo">
                                    <b-select v-model="tipoAnexoID" placeholder="Selecione o tipo de anexo">
                                        <option
                                        
                                            v-for="option in tipoAnexos"
                                            :value="option.AnaliseTipoAnexoID"
                                            :key="option.AnaliseTipoAnexoID">
                                            {{ option.Descricao }}
                                        </option>
                                    </b-select>
                                </b-field>
                            </div>
                            <div class="column is-two-thirds">
                                <b-field label="Arquivo">
                                              
                                        <b-input readonly v-model="file.name"></b-input>
                                        <b-field class=" ml-2 mr-2"  :class="{'has-name': !!file}">
                                            <b-upload v-model="file" class="is-primary file file-label">
                                                <span class="file-cta">
                                                    <b-icon class="file-icon" icon="magnifying-glass"></b-icon>

                                                    <span class="file-label">Procurar</span>
                                                </span>
                                            
                                            </b-upload>
                                        </b-field> 
                                        <b-button :disabled="!file.name || loading === true" icon-left="upload" class="file is-primary" @click.native="uploadArquivo">
                                                   Adicionar                                    
                                        </b-button> 
                              
                                </b-field>
                            </div>
                        </div>
                    </section>
                    <br>
                    <section>
                        <b-table
                            :data="data"
                            :loading="loading"
                            aria-next-label="Próxima"
                            aria-previous-label="Anterior"
                            aria-page-label="Page"
                            aria-current-label="Página atual"

                            >

                            <b-table-column field="id" label="ID"  v-slot="props">
                            {{ props.row.AnaliseSolicitacaoAnexoID }}
                            </b-table-column>

                            <b-table-column  field="Data" label="Data"  v-slot="props">
                                {{ new Date(props.row.DataHora) | dateFormat('DD/MM/YYYY HH:mm')}}
                            </b-table-column>
                            <b-table-column  field="Analista" label="Analista"  v-slot="props">
                                {{ props.row.usuario.Nome }}
                            </b-table-column>

                            <b-table-column  field="Tipo" label="Tipo"  v-slot="props">
                                {{ props.row.analise_tipo_anexo.Descricao }}
                            </b-table-column>

                            <b-table-column  field="Arquivo" label="Arquivo"  v-slot="props">
                                {{ props.row.NomeArquivo }}
                            </b-table-column>

                            <b-table-column  field="Acoes" label="Ações" v-slot="props" >
                                <b-button v-if="formProps.DataConclusao === null && userId === formProps.AnaliseUsuarioID" type="is-danger" outlined
                                    icon-right="trash"
                                    @click="deletarAnexoModal(props.row.AnaliseSolicitacaoAnexoID, props.row.NomeArquivo )"
                                     />

                                <b-button class="ml-2" type="is-success"
                                    icon-right="download"
                                    @click="downloadAnexo(props.row.AnaliseSolicitacaoAnexoID, props.row.NomeArquivo)"
                                    />
                            </b-table-column>

                        </b-table>
                    </section>
                </div>
            </div>
        </section>
        <footer class="modal-card-foot is-flex is-justify-content-end">

                        <b-button
                            label="Fechar"
                            type="is-primary"
                            @click="$emit('close')" />
        </footer>
    </div>

</template>


<script>

import services from '@/http';
import { mapState } from 'vuex'

export default {
    props: {
        formProps: Object,
    },
    data(){
        return{
            data: [],
            loading: false,
            tipoAnexos: [],
            file: {},
            tipoAnexoID: null,
            dataConclusao: this.formProps.DataConclusao
        }
    },
    methods: {

            analiseTipoAnexoLista(){
                services.analiseFraude.analiseTipoAnexoBuscar()
                    .then(({data}) => {
                        this.tipoAnexos = data.data;
                        this.tipoAnexoID = this.tipoAnexos[0].AnaliseTipoAnexoID;
                    })
            },
            deletarAnexoModal(id, nome){
                this.$buefy.dialog.confirm({
                    type: "is-danger",
                    message: 'Você deseja excluir o arquivo ' + nome + '?',
                    onConfirm: () => this.deletarAnexoSubmit(id, nome),
                    confirmText: "Sim",
                    cancelText:"Cancelar"
                })
            },
            deletarAnexoSubmit(id, nome = ''){
                const params = {
                    AnaliseSolicitacaoAnexoID: id
                }

                this.loading = true;

                services.analiseFraude.solicitacaoAnaliseDeletarAnexo(params)
                    .then(({data}) => {
                        if(!data.success){
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: data.msg,
                                position: 'is-bottom',
                                type: 'is-danger'
                                
                            })
                            return
                        }

                        this.buscarAnexos();


                        this.$buefy.toast.open({
                                duration: 5000,
                                message: nome + ' deletado com sucesso.',
                                position: 'is-bottom',
                                type: 'is-success'
                                
                        })
                    
                    
                    
                    }).catch(error => {
                        console.error('Erro na Promise:', error);
                    })
                    .finally(() => {
                        this.loading = false;  
                    })
                
            },
            downloadAnexo(id,nome){
                const params = {
                    AnaliseSolicitacaoAnexoID: id
                }

                this.loading = true;

                services.analiseFraude.solicitacaoAnaliseGerarUrlTemporaria(params)
                    .then(({data}) => {
                        const url = data.data;
                        this.forceFileDownload(url, nome);
                    
                }).finally(() => {
                    this.loading = false;  
                })
            },
            
            forceFileDownload(url,filename){
                    const anchor = document.createElement("a");
                    anchor.href = url;
                    anchor.setAttribute('target','_blank')
                    anchor.download = filename;

                    document.body.appendChild(anchor);
                    anchor.click();
                    document.body.removeChild(anchor);

            },
            uploadArquivo(){
                this.loading = true;
                var reader = new FileReader()
                reader.readAsDataURL(this.file)
                reader.onload = () => {
                    let base64result = reader.result.split(',')[1];
                    let params = {
                        AnaliseSolicitacaoID: this.formProps.AnaliseSolicitacaoID,
                        UsuarioID: this.userId,
                        AnaliseTipoAnexoID: this.tipoAnexoID,
                        NomeArquivo: this.file.name,
                        File: base64result 
                    }
                    services.analiseFraude.solicitacaoAnaliseAdicionarAnexo(params)
                    .then(({data}) => {
                        if(data.success){
                            this.file = {}
                            this.loading = false;
                            this.buscarAnexos();
                            return;
                        }

                        this.$buefy.toast.open({
                            duration: 5000,
                            message: data.msg,
                            position: 'is-bottom',
                            type: 'is-danger'
                        })

                            this.loading = false;

                    })
                    .catch((err) => {
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: `Não foi possível fazer upload do arquivo`,
                            position: 'is-bottom',
                            type: 'is-danger'
                        })
                    })
                    .finally(() => {
                    })

                };
            
            },
            buscarAnexos(){
                this.loading = true;

                const params = {
                    AnaliseSolicitacaoID: this.formProps.AnaliseSolicitacaoID
                }

                services.analiseFraude.solicitacaoAnaliseBuscarAnexos(params)
                    .then(({data}) => {
                       this.data = data.data;
                }).catch(error => {
                    console.error('Erro na Promise:', error);
                }).finally(() => {
                        this.loading = false;

                })
            }
    },
      computed: {
        ...mapState({
        userId(state) {
            return state.auth.user?.id;
        } 
  })},
    mounted(){
        this.analiseTipoAnexoLista();
        this.buscarAnexos();
    }
}

</script>