import IndexView from './pages/IndexView';

export default [
    {
        path: '/operacao/prestadores',
        name: 'operacao.prestadores',
        component: IndexView,
        meta: {
            permission: 'operacao.prestadores', 
            keepAlive: false
        }
    }
];