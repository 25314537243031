<template>
    <div class="p-1">
        <b-menu>
            <div v-cans="'dashboard.creddefense|dashboard.analiseFraude|analise.solicitacoes|acompanhamento.validacao.biometrica'">
                <b-menu-list label="PREVENCÃO A FRAUDES">
                    <div v-cans="'cadastros.analise.motivo|cadastros.analise.tipo|cadastros.analise.origem'">
                        <b-menu-list label="Cadastros">
                            <b-menu-item v-can="'cadastros.analise.motivo'" icon-pack="fas" tag="router-link" to="/analise/motivo" icon="cog" label="Motivos da Análise"/>
                            <b-menu-item v-can="'cadastros.analise.tipo'" icon-pack="fas" tag="router-link" to="/analise/tipo" icon="cog" label="Tipos de Análise"/>
                            <b-menu-item v-can="'cadastros.analise.origem'" icon-pack="fas" tag="router-link" to="/analise/origem" icon="cog" label="Origens de Análise"/>
                        </b-menu-list>
                    </div>
                    <div v-cans="'dashboard.creddefense|acompanhamento.validacao.biometrica'">
                        <b-menu-list label="Validação Biométrica">
                            <b-menu-item v-can="'acompanhamento.validacao.biometrica'" icon-pack="fas" tag="router-link" to="/acompanhamento/validacao/biometrica" 
                                icon="address-card"
                                label="Consulta Biométrica"/>
                            <b-menu-item v-can="'dashboard.creddefense'" icon-pack="fas" tag="router-link" to="/dashboard/creddefense" 
                                icon="address-card"
                                label="Histórico CredDefense"/>
                        </b-menu-list>
                    </div>
                    <div v-cans="'dashboard.analiseFraude|analise.solicitacoes'">
                        <b-menu-list label="SOLICITAÇÃO DE ANÁLISE">
                            <b-menu-item v-can="'dashboard.analiseFraude'" icon-pack="fas" tag="router-link" to="/dashboard/analiseFraude" 
                                icon="id-card-clip"
                                label="Painel de Solicitações"/>
                            <b-menu-item v-can="'analise.solicitacoes'" icon-pack="fas" tag="router-link" to="/analise/solicitacoes" 
                                icon="id-card-clip"
                                label="Solicitações de Análise"/>
                        </b-menu-list>
                    </div>
                    
                    <!-- Configurações do módulo -->
                    <div v-cans="'parametros.fraudes'">  
                        <b-menu-list label="Configurações">
                            <b-menu-item v-can="'parametros.fraudes'" icon-pack="fas" tag="router-link" to="/fraudes/parametros/1" 
                                icon="cog"
                                label="Parâmetros"/>
                            <b-menu-item v-can="'parametros.fraudes'" icon-pack="fas" tag="router-link" to="/fraudes/parametros_filial/1" 
                                icon="cog"
                                label="Parâmetros por Filial"/>
                        </b-menu-list>
                    </div>
                    
                </b-menu-list>
                
                <hr>
            </div>
            <div v-cans="'operacao.tipo|operacao.evento|dashboard.notificacaoMovimentacao'">
                <b-menu-list label="RECUPERAÇÃO DE VEÍCULOS">
                    <div v-cans="'operacao.tipo'">
                        <b-menu-list label="Cadastros">
                            <b-menu-item v-can="'operacao.tipo'" icon-pack="fas" icon="stream" tag="router-link" to="/operacao/tipo" label="Tipos de Operações"/>
                            <b-menu-item v-can="'operacao.statusEvento'" icon-pack="fas" icon="cog" tag="router-link" to="/operacao/eventoStatus" label="Status do Evento"/>
                            <b-menu-item v-can="'operacao.prestadores'" icon-pack="fas" tag="router-link" to="/operacao/prestadores" icon="address-card"
                                label="Prestadores"/>
                        </b-menu-list>
                    </div>
                    <div v-cans="'operacao.evento'">
                        <b-menu-list label="Eventos">
                            <b-menu-item v-can="'operacao.evento'" icon-pack="fas" icon="list-ol" tag="router-link" to="/operacao/evento" label="Painel de Eventos"/>
                        </b-menu-list>
                    </div>
                    <div v-cans="'dashboard.notificacaoMovimentacao'">
                        <b-menu-list label="Consultas">
                            <b-menu-item v-can="'dashboard.notificacaoMovimentacao'" icon-pack="fas" tag="router-link" to="/dashboard/notificacaoMovimentacao" 
                                icon="bell"
                                label="Notificações de AI"/>
                        </b-menu-list>
                    </div>

                    <!-- Configurações do módulo -->
                    <div v-cans="'parametros.recuperacoes'">  
                        <b-menu-list label="Configurações">
                            <b-menu-item v-can="'parametros.recuperacoes'" icon-pack="fas" tag="router-link" to="/recuperacoes/parametros/2" 
                                icon="cog"
                                label="Parâmetros"/>
                        </b-menu-list>
                    </div>
                </b-menu-list>
                <hr>
            </div>
            <b-menu-list label="Ações">
                <b-menu-item @click="submit" icon-pack="fas" icon="sign-out-alt" label="Logout"></b-menu-item>
            </b-menu-list>
        </b-menu>
    </div>
</template>

<style>
    .icon-text .icon {
        margin-right: 10px !important;
        font-size: 14px;
    }

    .menu-list .menu-label {
        padding-top: 20px;
        text-transform: uppercase;
        padding-left: 30px !important;
    }

    .menu-list > .menu-list {
        padding-left: 10px !important;
    }

    .menu-list a {
        padding-left: 30px !important;
    }
</style>

<script>
import {mapActions} from 'vuex';

export default{
    methods: {
            ...mapActions('auth', ['ActionSignOut']),
            submit() {
                this.ActionSignOut().then(res => {
                    this.$router.push('/').catch(() => {});
                })
            }
    }
}

</script>
