<template>
    <layout>
        <page-title subtitle="Listando os tipos de operação disponíveis">
            Tipos de operações

            <div slot="actions">
                <b-button type="is-primary is-light" @click="isFormModalActive = ! isFormModalActive"><i class="fa-solid fa-plus fa-fw mr-1"></i> Nova operação</b-button>
            </div>
        </page-title>

        <!-- Registros-->
        <div class="box my-5">
            <b-table 
                :data="data.data" 
                paginated
                backend-pagination
                :total="data.total"
                :per-page="data.per_page"
                @page-change="onPageChange"
                :current-page.sync="data.current_page"
                :pagination-rounded="true"
                :striped="true" 
                :hoverable="true" >
                
                <b-table-column label="Descrição" v-slot="props">
                    <router-link :to="'/operacao/tipo/' + props.row.TipoOperacaoID"><strong>{{ props.row.Descricao }}</strong></router-link>
                </b-table-column>

                <b-table-column label="Ativo?" centered v-slot="props">
                    <span v-if="props.row.Ativo" class="tag is-success">Ativo</span>
                    <span v-else class="tag is-danger">Inativo</span>
                </b-table-column>

                <b-table-column label="#" width="40" numeric v-slot="props">
                    {{ props.row.TipoOperacaoID }}
                </b-table-column>

                <b-table-column label="Ações" centered v-slot="props">
                    <b-tooltip label="Editar" type="is-dark">
                        <a href="#" @click.prevent="edit(props.index)"><i class="fa-solid fa-pen-to-square fa-fw"></i></a>
                    </b-tooltip>
                </b-table-column>
            </b-table>
        </div>

        <!-- Modal com formulário de cadastro -->
        <b-modal @close="clear" v-model="isFormModalActive" :width="640" scroll="keep">
            <div class="box">

                <div class="mb-4">
                    <h2 class="is-size-4 has-text-weight-bold">Atribuir motivos de análise</h2>
                    <small>Os motivos selecionados abaixo ficarão disponíveis para as solicitações de análise</small>
                    <hr>
                </div>

                <hr>
                <b-button @click="update" type="is-primary pulled-right"><i class="fa-solid fa-floppy-disk fa-fw mr-1"></i> Salvar</b-button>
                </div>
        </b-modal>

    </layout>
</template>


<script>
    import services from '@/http';
    import flash from '@/mixins/flash.js';
    import TipoForm from '@/modules/operacao/tipo/components/TipoForm.vue';

    export default {
        components: {
            TipoForm
        },
        mixins: [flash], 
        data() {
            return {
                tipo: null, 
                data: [], 
                isFormModalActive: false, 
            }
        }, 
        methods: {
            fetch(page = 1) {
                this.$http.get('api/v1/operacao/tipo?page=' + page).then(res => this.data = res.body.response);
            }, 

            onPageChange(page)
            {
                this.fetch(page)
            }, 

            edit(index) {
                this.isFormModalActive = true;                
                this.tipo = this.data.data[index];
            }, 

            save() {
                this.tipo.TipoOperacaoID === undefined ? this.store() : this.update(this.tipo)
            }, 

            store() {
                services.operacaoTipo.store(this.tipo).then(res => {
                    if (res.body.success) {

                        this.flash('success', res.body.response.message)

                        this.isFormModalActive = false;
                        this.clear()
                        this.fetch()
                    } else {
                        this.flash('danger', res.body.response[Object.keys(res.body.response)[0]])
                    }
                })
            }, 

            update(tipo) {
                
                services.operacaoTipo.update({ tipo: tipo.TipoOperacaoID }, tipo).then(res => {
                    if (res.body.success) {

                        this.flash('success', res.body.response.message)

                        this.isFormModalActive = false;
                        this.clear()
                        this.fetch()
                    } else {
                        this.flash('danger', res.body.response[Object.keys(res.body.response)[0]])
                    }
                })
            }, 

            clear() {
                this.tipo = {
                    Descricao: '', 
                    Ativo: '', 
                }
            }
        }, 
        mounted() {
            this.fetch()
            this.clear()

            this.$root.$on('save-form', () => {
                this.save()
            })
        }
    }

</script>
