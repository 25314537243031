<template>
    <layout>
        <page-title subtitle="Listando os tipos de operação disponíveis">
            Status do Evento

            <div slot="actions">
                <b-button type="is-primary is-light" @click="isFormModalActive = ! isFormModalActive"><i class="fa-solid fa-plus fa-fw mr-1"></i> Novo Status </b-button>
            </div>
        </page-title>

        <!-- Registros-->
        <div class="box my-5">
            <b-table 
                :data="data" 
                :striped="true" 
                :hoverable="true" >
                
                <b-table-column label="Descrição" v-slot="props">
                    <a @click.prevent="edit(props.index)"><strong>{{ props.row.Descricao }}</strong></a>
                </b-table-column>

                <b-table-column label="Ativo?" centered v-slot="props">
                    <span v-if="props.row.Ativo == 'S'" class="tag is-success">Ativo</span>
                    <span v-else class="tag is-danger">Inativo</span>
                </b-table-column>

                <b-table-column label="#" width="40" numeric v-slot="props">
                    {{ props.row.EventoStatusID }}
                </b-table-column>

                <b-table-column label="Ações" centered v-slot="props">
                    <b-tooltip label="Editar" type="is-dark">
                        <a href="#" @click.prevent="edit(props.index)"><i class="fa-solid fa-pen-to-square fa-fw"></i></a>
                    </b-tooltip>
                </b-table-column>
            </b-table>
        </div>

        <!-- Modal com formulário de cadastro -->
        <b-modal @close="clear" v-model="isFormModalActive" :width="640" scroll="keep">
            <tipo-form @save="save" :tipo="tipo"></tipo-form>
        </b-modal>
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>

    </layout>
</template>


<script>
    import services from '@/http';
    import flash from '@/mixins/flash.js';
    import TipoForm from '@/modules/operacao/tipo/components/TipoFormV2.vue';

    export default {
        components: {
            TipoForm
        },
        mixins: [flash], 
        data() {
            return {
                tipo: null, 
                data: [], 
                isFormModalActive: false,
                isLoading: false
            }
        }, 
        methods: {
            fetch() {
                  this.isLoading = true;
                services.operacaoEventoStatus.get().then(res => this.data = res.body.response) .finally(() => {
                        this.isLoading = false;
                    });
            }, 

            edit(index) {
                this.isFormModalActive = true;
                this.tipo = this.data[index];
            }, 

            save() {
                this.tipo.EventoStatusID === undefined ? this.store() : this.update(this.tipo)
            }, 

            store() {
                if(this.tipo.Descricao == '' || this.tipo.Ativo == '' || typeof this.tipo.Ativo == 'undefined'){
                    this.flash('danger', "Todos os campos obrigatórios precisam ser preenchidos.")
                    return;
                }

                this.isLoading = true;

                services.operacaoEventoStatus.store(this.tipo).then(res => {
                    if (res.body.success) {

                        this.flash('success', res.body.response.msg)

                        this.isFormModalActive = false;
                        this.clear()
                        this.fetch()
                    } else {
                        this.flash('danger', res.body.response.msg)
                    }
                }).finally(() => {
                    this.isLoading = false;
                })
            }, 

            update(tipo) {
                if(this.tipo.Descricao == '' || this.tipo.Ativo == '' || typeof this.tipo.Ativo == 'undefined'){
                    this.flash('danger', "Todos os campos obrigatórios precisam ser preenchidos.")
                    this.clear()
                    this.fetch()
                    return;
                }

                this.isLoading = true;
                services.operacaoEventoStatus.update({ tipo: tipo.EventoStatusID }, tipo).then(res => {
                    if (res.body.success) {

                        this.flash('success', res.body.response.msg)

                        this.isFormModalActive = false;
                        this.clear()
                        this.fetch()
                    } else {
                        this.flash('danger', res.body.response.msg)
                        
                    }
                }).finally(() => {
                    this.isLoading = false;
                })
            }, 

            clear() {
                this.tipo = {
                    Descricao: '', 
                    Ativo: '', 
                }
            }
        }, 
        mounted() {
            this.fetch()
            this.clear()

            this.$root.$on('save-form', () => {
                this.save()
            })
        }
    }

</script>
